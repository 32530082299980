import * as React from "react";
import axios from "axios";
import AuthToken from "../../helpers/AuthToken";
import { withSnackbar } from "notistack";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";

import { navigate } from "gatsby";
import TitleNav from "../TitleNav";

import Layout from "../Layout";
import { isBrowser, signOut } from "../../helpers/LocalStorage";
import { yesNo } from "../../helpers/Lists";

class AddOrganization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hostname: ``,
      organizationName: ``,
      typeId: ``,
      types: [],
      operatingStructureId: ``,
      operatingStructures: [],
      description: ``,
      website: ``,
      twitter: ``,
      instagram: ``,
      address: ``,
      statusId: ``,
      statuses: [],
      enabled: `Yes`,
      exchange: `No`,
      exchangeEmail: ``,
      exchangeDescription: ``,
      regions: [],
      regionIds: [],
      verticals: [],
      verticalIds: [],
      loading: false,
    };
  }

  componentDidMount() {
    AuthToken();
    Promise.all([
      axios.get(`${process.env.GATSBY_API_URL}/operating-structures`),
      axios.get(`${process.env.GATSBY_API_URL}/regions`),
      axios.get(`${process.env.GATSBY_API_URL}/organization-statuses`),
      axios.get(`${process.env.GATSBY_API_URL}/organization-types`),
      axios.get(`${process.env.GATSBY_API_URL}/verticals`),
    ])
      .then((results) => {
        this.setState({
          operatingStructures: results[0].data,
          regions: results[1].data,
          statuses: results[2].data,
          types: results[3].data,
          verticals: results[4].data,
        });
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            this.props.enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          this.props.enqueueSnackbar("Unable to connect to the API", { variant: "error" });
        }
      });
  }

  handleUpdate = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleRegionsSelectUpdate = (event, values) => {
    this.setState({
      regionIds: values,
    });
  };

  handleVerticalsSelectUpdate = (event, values) => {
    this.setState({
      verticalIds: values,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const data = {};
    data.hostname = this.state.hostname;
    data.name = this.state.organizationName;
    data.organizationTypeID = this.state.typeId;
    data.operatingStructureID = this.state.operatingStructureId;
    data.description = this.state.description;
    data.address = this.state.address;
    data.website = this.state.website;
    data.twitter = this.state.twitter;
    data.instagram = this.state.instagram;
    if (this.state.exchange === "Yes") {
      data.exchange = true;
    } else {
      data.exchange = false;
    }
    data.exchangeEmail = this.state.exchangeEmail;
    data.exchangeDescription = this.state.exchangeDescription;
    data.organizationStatusID = this.state.statusId;

    var regionIds = [];
    if (this.state.regionIds.length > 0) {
      this.state.regionIds.forEach((item) => {
        regionIds.push(item.id);
      });
    }
    data.regions = regionIds;

    var verticalIds = [];
    if (this.state.verticalIds.length > 0) {
      this.state.verticalIds.forEach((item) => {
        verticalIds.push(item.id);
      });
    }
    data.verticals = verticalIds;

    if (this.state.enabled === "Yes") {
      data.enabled = true;
    } else {
      data.enabled = false;
    }

    this.setState({ loading: true });
    AuthToken();
    axios
      .post(`${process.env.GATSBY_API_URL}/organizations`, data)
      .then((response) => {
        if (isBrowser()) {
          navigate("/app/organizations/" + response.data.id);
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        if (err.response.status === 401 || err.response.status === 403) {
          signOut();
        } else {
          this.props.enqueueSnackbar(err.response.data.reason, {
            variant: "error",
          });
        }
      });
  };

  render() {
    return (
      <Layout>
        <Paper elevation={1} sx={{ p: 2 }}>
          <TitleNav title="Add Organization" buttonLink="/app/organizations" buttonTitle="Organizations" />
          <Box sx={{ m: 2 }} />
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="hostname"
                  label="Subdomain"
                  name="hostname"
                  value={this.state.hostname}
                  onChange={this.handleUpdate}
                  helperText="If subdomain is example, the url will be https://example.connects.community"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="organizationName"
                  label="Name"
                  name="organizationName"
                  value={this.state.organizationName}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  multiline
                  minRows={5}
                  id="description"
                  label="Description"
                  name="description"
                  value={this.state.description}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  multiline
                  minRows={5}
                  id="address"
                  label="Address"
                  name="address"
                  value={this.state.address}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="enabled"
                  select
                  required
                  label="Enabled"
                  name="enabled"
                  value={this.state.enabled}
                  onChange={this.handleUpdate}
                  variant="outlined"
                  fullWidth
                >
                  {yesNo().map((option) => (
                    <MenuItem value={option.value} key={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  select
                  required
                  fullWidth
                  id="typeId"
                  label="Type"
                  name="typeId"
                  value={this.state.typeId}
                  onChange={this.handleUpdate}
                >
                  {this.state.types.map((option) => (
                    <MenuItem value={option.id} key={option.id}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  select
                  required
                  fullWidth
                  id="operatingStructureId"
                  label="Operating Structure"
                  name="operatingStructureId"
                  value={this.state.operatingStructureId}
                  onChange={this.handleUpdate}
                >
                  {this.state.operatingStructures.map((option) => (
                    <MenuItem value={option.id} key={option.id}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  select
                  required
                  fullWidth
                  id="statusId"
                  label="Status"
                  name="statusId"
                  value={this.state.statusId}
                  onChange={this.handleUpdate}
                >
                  {this.state.statuses.map((option) => (
                    <MenuItem value={option.id} key={option.id}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  id="regionIds"
                  value={this.state.regionIds}
                  options={this.state.regions}
                  getOptionLabel={(option) => option.label}
                  filterSelectedOptions
                  onChange={this.handleRegionsSelectUpdate}
                  renderInput={(params) => <TextField {...params} variant="outlined" label="Regions" />}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  id="verticalFocusIds"
                  options={this.state.verticals}
                  getOptionLabel={(option) => option.label}
                  filterSelectedOptions
                  onChange={this.handleVerticalsSelectUpdate}
                  value={this.state.verticalIds}
                  renderInput={(params) => <TextField {...params} variant="outlined" label="Verticals" />}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="website"
                  label="Website URL"
                  name="website"
                  value={this.state.website}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="twitter"
                  label="Twitter Username"
                  name="twitter"
                  value={this.state.twitter}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="instagram"
                  label="Instagram Username"
                  name="instagram"
                  value={this.state.instagram}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  select
                  required
                  label="Global Workspace Connect"
                  name="exchange"
                  id="exchange"
                  value={this.state.exchange}
                  onChange={this.handleUpdate}
                  variant="outlined"
                  fullWidth
                >
                  {yesNo().map((option) => (
                    <MenuItem value={option.value} key={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="exchangeEmail"
                  label="Global Workspace Connect Email"
                  name="exchangeEmail"
                  value={this.state.exchangeEmail}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  multiline
                  minRows={5}
                  id="exchangeDescription"
                  label="Global Workspace Connect Description"
                  name="exchangeDescription"
                  value={this.state.exchangeDescription}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid container item xs={12} justifyContent="right">
                <LoadingButton
                  fullWidth
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  loading={this.state.loading}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Layout>
    );
  }
}

export default withSnackbar(AddOrganization);
