import * as React from "react";
import axios from "axios";
import AuthToken from "../../helpers/AuthToken";
import { Typography } from "@mui/material";
import { withSnackbar } from "notistack";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { Editor } from "@tinymce/tinymce-react";
import Divider from "@mui/material/Divider";
import TitleNav from "../TitleNav";
import Layout from "../Layout";
import { navigate } from "gatsby";
import { isBrowser, signOut } from "../../helpers/LocalStorage";
import FeaturedSelect from "../widgets/FeaturedSelect";
import OrganizationCanViewSelect from "../widgets/OrganizationCanViewSelect";
import CategoriesAutocomplete from "../widgets/CategoriesAutocomplete";
import TagsAutocomplete from "../widgets/TagsAutocomplete";
import VerticalsAutocomplete from "../widgets/VerticalsAutocomplete";
import OperatingStructuresAutocomplete from "../widgets/OperatingStructuresAutocomplete";
import OrganizationTypesAutocomplete from "../widgets/OrganizationTypesAutocomplete";
import OrganizationStatusesAutocomplete from "../widgets/OrganizationStatusesAutocomplete";
import RegionsAutocomplete from "../widgets/RegionsAutocomplete";

class EditPastMeetup extends React.Component {
  constructor(props) {
    super(props);
    this.editorRef = React.createRef();
    this.state = {
      title: ``,
      description: ``,
      featured: `No`,
      organizationCanView: `Yes`,
      tagIds: [],
      categoryIds: [],
      orgOrganizationTypeIds: [],
      orgVerticalIds: [],
      orgOperatingStructureIds: [],
      orgTagIds: [],
      orgRegionIds: [],
      orgOrganizationStatusIds: [],
      loading: false,
    };
  }

  componentDidMount() {
    AuthToken();
    axios
      .get(`${process.env.GATSBY_API_URL}/past-meetups/${this.props.pastMeetupId}`)
      .then((response) => {
        const pastMeetup = response.data;
        this.setState({
          title: pastMeetup.title,
          description: pastMeetup.description,
          featured: pastMeetup.featured ? "Yes" : "No",
          organizationCanView: pastMeetup.organizationCanView ? "Yes" : "No",
          tagIds: pastMeetup.tags,
          categoryIds: pastMeetup.categories,
          orgOrganizationTypeIds: pastMeetup.organizationTypes,
          orgVerticalIds: pastMeetup.organizationVerticals,
          orgOperatingStructureIds: pastMeetup.organizationOperatingStructures,
          orgTagIds: pastMeetup.organizationTags,
          orgRegionIds: pastMeetup.organizationRegions,
          orgOrganizationStatusIds: pastMeetup.organizationStatuses,
        });
      })
      .catch((err) => {
        if (err.response.status === 401 || err.response.status === 403) {
          signOut();
        } else if (err.response.status === 404) {
          if (isBrowser()) {
            navigate("/app/past-meetups");
          }
        } else {
          this.props.enqueueSnackbar(err.response.data.reason, {
            variant: "error",
          });
        }
      });
  }

  handleUpdate = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  featured = (value) => {
    this.setState({
      featured: value,
    });
  };

  organizationCanView = (value) => {
    this.setState({
      organizationCanView: value,
    });
  };

  handleOrgOrganizationTypesUpdate = (values) => {
    this.setState({
      orgOrganizationTypeIds: values,
    });
  };

  handleTagSelectUpdate = (values) => {
    this.setState({
      tagIds: values,
    });
  };

  handleCategorySelectUpdate = (values) => {
    this.setState({
      categoryIds: values,
    });
  };

  handleOrgVerticalsSelectUpdate = (values) => {
    this.setState({
      orgVerticalIds: values,
    });
  };

  handleOrgOperatingStructuresSelectUpdate = (values) => {
    this.setState({
      orgOperatingStructureIds: values,
    });
  };

  handleOrgTagsSelectUpdate = (values) => {
    this.setState({
      orgTagIds: values,
    });
  };

  handleOrgRegionsSelectUpdate = (values) => {
    this.setState({
      orgRegionIds: values,
    });
  };

  handleOrgOrganizationStatusesSelectUpdate = (values) => {
    this.setState({
      orgOrganizationStatusIds: values,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const data = {};
    data.title = this.state.title;
    data.description = this.editorRef.current.getContent();

    if (this.state.featured === "Yes") {
      data.featured = true;
    } else {
      data.featured = false;
    }

    if (this.state.organizationCanView === "Yes") {
      data.organizationCanView = true;
    } else {
      data.organizationCanView = false;
    }

    var tagIds = [];
    if (this.state.tagIds.length > 0) {
      this.state.tagIds.forEach((item) => {
        tagIds.push(item.id);
      });
    }
    data.tags = tagIds;

    var categoryIds = [];
    if (this.state.categoryIds.length > 0) {
      this.state.categoryIds.forEach((item) => {
        categoryIds.push(item.id);
      });
    }
    data.categories = categoryIds;

    var orgOrganizationTypeIds = [];
    if (this.state.orgOrganizationTypeIds.length > 0) {
      this.state.orgOrganizationTypeIds.forEach((item) => {
        orgOrganizationTypeIds.push(item.id);
      });
    }
    data.organizationTypes = orgOrganizationTypeIds;

    var orgVerticalIds = [];
    if (this.state.orgVerticalIds.length > 0) {
      this.state.orgVerticalIds.forEach((item) => {
        orgVerticalIds.push(item.id);
      });
    }
    data.organizationVerticals = orgVerticalIds;

    var orgOperatingStructureIds = [];
    if (this.state.orgOperatingStructureIds.length > 0) {
      this.state.orgOperatingStructureIds.forEach((item) => {
        orgOperatingStructureIds.push(item.id);
      });
    }
    data.organizationOperatingStructures = orgOperatingStructureIds;

    var orgTagIds = [];
    if (this.state.orgTagIds.length > 0) {
      this.state.orgTagIds.forEach((item) => {
        orgTagIds.push(item.id);
      });
    }
    data.organizationTags = orgTagIds;

    var orgRegionIds = [];
    if (this.state.orgRegionIds.length > 0) {
      this.state.orgRegionIds.forEach((item) => {
        orgRegionIds.push(item.id);
      });
    }
    data.organizationRegions = orgRegionIds;

    var orgOrganizationStatusIds = [];
    if (this.state.orgOrganizationStatusIds.length > 0) {
      this.state.orgOrganizationStatusIds.forEach((item) => {
        orgOrganizationStatusIds.push(item.id);
      });
    }
    data.organizationStatuses = orgOrganizationStatusIds;

    this.setState({ loading: true });
    AuthToken();
    axios
      .put(`${process.env.GATSBY_API_URL}/past-meetups/${this.props.pastMeetupId}`, data)
      .then(() => {
        this.props.enqueueSnackbar("Past meetup updated", {
          variant: "success",
        });
        if (isBrowser()) {
          navigate("/app/past-meetups");
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        if (err.response.status === 401 || err.response.status === 403) {
          signOut();
        } else {
          this.props.enqueueSnackbar(err.response.data.reason, {
            variant: "error",
          });
        }
      });
  };

  render() {
    return (
      <Layout>
        <Paper elevation={1} sx={{ p: 2 }}>
          <TitleNav title="Edit Past Meetup" buttonLink="/app/past-meetups" buttonTitle="Past Meetups" />
          <Box sx={{ m: 2 }} />
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="title"
                  label="Title"
                  name="title"
                  value={this.state.title}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Description</Typography>
                <Editor
                  apiKey={`${process.env.GATSBY_TINY_MCE_API_KEY}`}
                  initialValue={this.state.description}
                  onInit={(evt, editor) => (this.editorRef.current = editor)}
                  init={{
                    height: 500,
                    menubar: false,
                    plugins: "lists link paste code",
                    toolbar:
                      "undo redo | bold italic | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | link | code",
                  }}
                />
                <Typography variant="caption">
                  Any fonts or colors set here will overide the fonts and colors in the member site
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FeaturedSelect required featured={this.state.featured} selectChanged={this.featured} />
              </Grid>
              <Grid item xs={6}>
                <OrganizationCanViewSelect
                  canView={this.state.organizationCanView}
                  selectChanged={this.organizationCanView}
                />
              </Grid>
              <Grid item xs={12}>
                <CategoriesAutocomplete
                  categoryType="Past Meetup"
                  ids={this.state.categoryIds}
                  selectChanged={this.handleCategorySelectUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <TagsAutocomplete
                  tagType="Past Meetup"
                  ids={this.state.tagIds}
                  selectChanged={this.handleTagSelectUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider>Organization Filters</Divider>
              </Grid>
              <Grid item xs={12}>
                <OrganizationTypesAutocomplete
                  ids={this.state.orgOrganizationTypeIds}
                  selectChanged={this.handleOrgOrganizationTypesUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <VerticalsAutocomplete
                  ids={this.state.orgVerticalIds}
                  selectChanged={this.handleOrgVerticalsSelectUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <OperatingStructuresAutocomplete
                  ids={this.state.orgOperatingStructureIds}
                  selectChanged={this.handleOrgOperatingStructuresSelectUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <TagsAutocomplete
                  tagType="PastMeetup"
                  ids={this.state.orgTagIds}
                  selectChanged={this.handleOrgTagsSelectUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <RegionsAutocomplete ids={this.state.orgRegionIds} selectChanged={this.handleOrgRegionsSelectUpdate} />
              </Grid>
              <Grid item xs={12}>
                <OrganizationStatusesAutocomplete
                  ids={this.state.orgOrganizationStatusIds}
                  selectChanged={this.handleOrgOrganizationStatusesSelectUpdate}
                />
              </Grid>
              <Grid container item xs={12} justifyContent="right">
                <LoadingButton
                  fullWidth
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  loading={this.state.loading}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Layout>
    );
  }
}

export default withSnackbar(EditPastMeetup);
