import * as React from "react";
import axios from "axios";
import { withSnackbar } from "notistack";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { navigate } from "gatsby";

import TitleNav from "../../TitleNav";
import AuthToken from "../../../helpers/AuthToken";
import Layout from "../../Layout";
import { isBrowser, signOut } from "../../../helpers/LocalStorage";

class AddIndustryTag extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      label: ``,
    };
  }

  handleUpdate = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    const data = {
      label: this.state.label,
    };

    event.preventDefault();
    AuthToken();
    axios
      .post(`${process.env.GATSBY_API_URL}/industry-tags`, data)
      .then(() => {
        if (isBrowser()) {
          navigate("/app/industry-tags");
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            this.props.enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          this.props.enqueueSnackbar("Unable to connect to the API", { variant: "error" });
        }
      });
  };

  render() {
    return (
      <Layout>
        <Paper elevation={1} sx={{ p: 2 }}>
          <TitleNav title="Add Industry Tag" buttonLink="/app/industry-tags" buttonTitle="Industry Tags" />
          <Box sx={{ m: 2 }} />
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="label"
                  label="Industry Tag"
                  name="label"
                  value={this.state.label}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid container item xs={12} justifyContent="right">
                <Button type="submit" variant="contained" color="primary">
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Layout>
    );
  }
}

export default withSnackbar(AddIndustryTag);
