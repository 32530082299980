import * as React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { withSnackbar, useSnackbar } from "notistack";
import Layout from "../Layout";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TitleNav from "../TitleNav";

import moment from "moment";

import { signOut } from "../../helpers/LocalStorage";

import AuthToken from "../../helpers/AuthToken";

function Settings() {
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleCellEditCommit = React.useCallback(
    async (params) => {
      AuthToken();
      const data = { [params.field]: params.value };
      axios
        .patch(`${process.env.GATSBY_API_URL}/settings/${params.id}`, data)
        .then(() => {
          enqueueSnackbar("Setting updated", { variant: "success" });
        })
        .catch(() => {
          enqueueSnackbar("Error while saving setting", { variant: "error" });
          setRows((prev) => [...prev]);
        });
    },
    [enqueueSnackbar],
  );

  const columns = React.useMemo(
    () => [
      { field: "id", headerName: "ID", hide: true },
      { field: "setting", headerName: "Setting", editable: true, width: 150 },
      { field: "key", headerName: "Key", editable: true, width: 150 },
      { field: "value", headerName: "Value", editable: true, width: 300 },
      {
        field: "createdAt",
        headerName: "Created",
        width: 225,
        type: "dateTime",
        valueFormatter: (params) => {
          const valueFormatted = moment(params.value).format("llll");
          return `${valueFormatted}`;
        },
      },
      {
        field: "updatedAt",
        headerName: "Updated",
        width: 225,
        type: "dateTime",
        valueFormatter: (params) => {
          const valueFormatted = moment(params.value).format("llll");
          return `${valueFormatted}`;
        },
      },
    ],
    [],
  );

  React.useEffect(() => {
    setIsLoading(true);
    AuthToken();
    axios
      .get(`${process.env.GATSBY_API_URL}/settings`)
      .then((response) => {
        setRows(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          enqueueSnackbar("Unable to connect to the API", { variant: "error" });
        }
      });
  }, [enqueueSnackbar]);

  return (
    <Layout>
      <Paper
        elevation={1}
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <TitleNav title="Settings" buttonLink="/app/settings/add" buttonTitle="Setting" addIcon />
        <Box sx={{ m: 1 }} />
        <div style={{ height: 575, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            disableColumnMenu
            loading={isLoading}
            components={{
              Toolbar: GridToolbar,
            }}
            onCellEditCommit={handleCellEditCommit}
          />
        </div>
      </Paper>
    </Layout>
  );
}

export default withSnackbar(Settings);
