import * as React from "react";
import axios from "axios";
import AuthToken from "../../helpers/AuthToken";
import { withSnackbar } from "notistack";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import Layout from "../Layout";
import TitleNav from "../TitleNav";

import { navigate } from "gatsby";
import { isBrowser, signOut } from "../../helpers/LocalStorage";

class AddSetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      setting: ``,
      key: ``,
      value: ``,
      loading: false,
    };
  }

  handleUpdate = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const data = {};
    data.setting = this.state.setting;
    data.key = this.state.key;
    data.value = this.state.value;

    this.setState({ loading: true });
    AuthToken();
    axios
      .post(`${process.env.GATSBY_API_URL}/settings`, data)
      .then(() => {
        this.props.enqueueSnackbar("Setting added", {
          variant: "success",
        });
        if (isBrowser()) {
          navigate("/app/settings");
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            this.props.enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          this.props.enqueueSnackbar("Unable to connect to the API", { variant: "error" });
        }
      });
  };

  render() {
    return (
      <Layout>
        <Paper elevation={1} sx={{ p: 2 }}>
          <TitleNav title="Add Setting" buttonLink="/app/settings" buttonTitle="Settings" />
          <Box sx={{ m: 2 }} />
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="setting"
                  label="Setting"
                  name="setting"
                  value={this.state.setting}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="key"
                  label="Key"
                  name="key"
                  value={this.state.key}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="value"
                  label="Value"
                  name="value"
                  value={this.state.value}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid container item xs={12} justifyContent="right">
                <LoadingButton
                  fullWidth
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  loading={this.state.loading}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Layout>
    );
  }
}

export default withSnackbar(AddSetting);
