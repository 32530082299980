import * as React from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import AuthToken from "../../helpers/AuthToken";
import { withSnackbar } from "notistack";
import { signOut } from "../../helpers/LocalStorage";
import axios from "axios";

class ConnectionWorkflowTierSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
    };
  }

  handleUpdate = (event) => {
    this.props.selectChanged(event.target.value);
  };

  componentDidMount() {
    AuthToken();
    axios
      .get(`${process.env.GATSBY_API_URL}/lists/connection-workflow-tiers`)
      .then((response) => {
        this.setState({
          options: response.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            this.props.enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          this.props.enqueueSnackbar("Cannot connect to server", {
            variant: "error",
          });
        }
      });
  }

  render() {
    return (
      <TextField
        id="connectionWorkflowTier"
        select
        required={this.props.required}
        label="Connection Workflow Tier"
        name="connectionWorkflowTier"
        value={this.props.connectionWorkflowTier}
        onChange={this.handleUpdate}
        variant="outlined"
        fullWidth
      >
        {this.state.options.map((option) => (
          <MenuItem value={option} key={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
    );
  }
}

export default withSnackbar(ConnectionWorkflowTierSelect);
