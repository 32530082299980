import * as React from "react";
import { DataGrid, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";
import axios from "axios";
import { withSnackbar, useSnackbar } from "notistack";
import Layout from "../Layout";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ToolTip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import StarsIcon from "@mui/icons-material/Stars";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DiscountIcon from "@mui/icons-material/Discount";
import TitleNav from "../TitleNav";

import moment from "moment";
import { navigate } from "gatsby";
import { isBrowser, signOut } from "../../helpers/LocalStorage";

import AuthToken from "../../helpers/AuthToken";

function Perks() {
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const deletePerk = React.useCallback(
    (id) => () => {
      AuthToken();
      axios
        .delete(`${process.env.GATSBY_API_URL}/perks/${id}`)
        .then(() => {
          enqueueSnackbar("Deleted perk", { variant: "success" });
          setRows((prevRows) => prevRows.filter((row) => row.id !== id));
        })
        .catch((err) => {
          console.error(err);
          if (err.response) {
            if (err.response.status === 401 || err.response.status === 403) {
              signOut();
            } else {
              enqueueSnackbar(err.response.data.reason, {
                variant: "error",
              });
            }
          } else {
            enqueueSnackbar("Unable to connect to the API", { variant: "error" });
          }
        });
    },
    [enqueueSnackbar],
  );

  const editPerk = React.useCallback(
    (id) => () => {
      if (isBrowser()) {
        navigate("/app/perks/" + id);
      }
    },
    [],
  );

  const duplicatePerk = React.useCallback(
    (id) => () => {
      AuthToken();
      axios
        .post(`${process.env.GATSBY_API_URL}/perks/${id}/duplicate`)
        .then((response) => {
          enqueueSnackbar("Perk duplicated", { variant: "success" });
          if (isBrowser()) {
            navigate("/app/perks/" + response.data.id);
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401 || err.response.status === 403) {
              signOut();
            } else {
              enqueueSnackbar(err.response.data.reason, {
                variant: "error",
              });
            }
          } else {
            enqueueSnackbar("Unable to connect to the API", { variant: "error" });
          }
        });
    },
    [enqueueSnackbar],
  );

  const columns = React.useMemo(
    () => [
      { field: "id", headerName: "ID", hide: true },
      {
        field: "featured",
        width: 100,
        type: "boolean",
        renderHeader: () => (
          <ToolTip title="Featured">
            <StarsIcon />
          </ToolTip>
        ),
      },
      {
        field: "redeemed",
        headerName: "Redeemed",
        width: 100,
        type: "number",
        renderHeader: () => (
          <ToolTip title="Redeemed">
            <DiscountIcon />
          </ToolTip>
        ),
      },
      { field: "name", headerName: "Perk", width: 200 },
      { field: "label", headerName: "Internal Name", width: 200 },
      {
        field: "url",
        headerName: "Perk URL",
        width: 250,
        renderCell: (params) => (
          <Button href={`${params.value}`} variant="text" size="small" style={{ textTransform: "lowercase" }}>
            {params.value}
          </Button>
        ),
      },
      {
        field: "user",
        headerName: "Created By",
        width: 200,
        valueFormatter: (params) => {
          return params.value.firstName + " " + params.value.lastName;
        },
      },
      {
        field: "createdAt",
        headerName: "Created",
        width: 225,
        type: "dateTime",
        valueFormatter: (params) => {
          const valueFormatted = moment(params.value).format("llll");
          return `${valueFormatted}`;
        },
      },
      {
        field: "updatedAt",
        headerName: "Updated",
        width: 225,
        type: "dateTime",
        valueFormatter: (params) => {
          const valueFormatted = moment(params.value).format("llll");
          return `${valueFormatted}`;
        },
      },
      {
        field: "actions",
        type: "actions",
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem icon={<EditIcon />} label="Edit" onClick={editPerk(params.id)} showInMenu />,
          <GridActionsCellItem
            icon={<ContentCopyIcon />}
            label="Duplicate"
            onClick={duplicatePerk(params.id)}
            showInMenu
          />,
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={deletePerk(params.id)} showInMenu />,
        ],
      },
    ],
    [editPerk, duplicatePerk, deletePerk],
  );

  React.useEffect(() => {
    setIsLoading(true);
    AuthToken();
    axios
      .get(`${process.env.GATSBY_API_URL}/perks`)
      .then((response) => {
        setRows(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          enqueueSnackbar("Unable to connect to the API", { variant: "error" });
        }
      });
  }, [enqueueSnackbar]);

  return (
    <Layout>
      <Paper
        elevation={1}
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <TitleNav title="Perks" buttonLink="/app/perks/add" buttonTitle="Perk" addIcon />
        <Box sx={{ m: 1 }} />
        <div style={{ height: 575, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            disableColumnMenu
            loading={isLoading}
            components={{
              Toolbar: GridToolbar,
            }}
          />
        </div>
      </Paper>
    </Layout>
  );
}

export default withSnackbar(Perks);
