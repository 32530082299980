import * as React from "react";
import axios from "axios";
import AuthToken from "../../helpers/AuthToken";
import { withSnackbar } from "notistack";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Layout from "../Layout";
import TitleNav from "../TitleNav";

import { navigate } from "gatsby";
import { isBrowser, signOut } from "../../helpers/LocalStorage";
import { yesNo } from "../../helpers/Lists";

class EditOrganization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hostname: ``,
      organizationName: ``,
      typeId: ``,
      types: [],
      logoURL: ``,
      operatingStructureId: ``,
      operatingStructures: [],
      description: ``,
      website: ``,
      twitter: ``,
      instagram: ``,
      address: ``,
      statusId: ``,
      statuses: [],
      enabled: `Yes`,
      exchange: `No`,
      exchangeEmail: ``,
      exchangeDescription: ``,
      regions: [],
      regionIds: [],
      verticals: [],
      verticalIds: [],
      loading: false,
      uploading: false,
    };
  }

  componentDidMount() {
    AuthToken();
    Promise.all([
      axios.get(`${process.env.GATSBY_API_URL}/operating-structures`),
      axios.get(`${process.env.GATSBY_API_URL}/regions`),
      axios.get(`${process.env.GATSBY_API_URL}/organization-statuses`),
      axios.get(`${process.env.GATSBY_API_URL}/organization-types`),
      axios.get(`${process.env.GATSBY_API_URL}/verticals`),
      axios.get(`${process.env.GATSBY_API_URL}/organizations/${this.props.organizationId}`),
    ])
      .then((results) => {
        const organization = results[5].data;

        var enabled = "No";
        if (organization.enabled) {
          enabled = "Yes";
        }

        var exchange = "No";
        if (organization.exchange) {
          exchange = "Yes";
        }

        this.setState({
          hostname: organization.hostname,
          organizationName: organization.name,
          logoURL: organization.logo,
          description: organization.description,
          address: organization.address,
          typeId: organization.organizationType.id,
          operatingStructureId: organization.operatingStructure.id,
          statusId: organization.organizationStatus.id,
          regionIds: organization.regions,
          verticalIds: organization.verticals,
          website: organization.website,
          twitter: organization.twitter,
          instagram: organization.instagram,
          enabled: enabled,
          exchange: exchange,
          exchangeEmail: organization.exchangeEmail,
          exchangeDescription: organization.exchangeDescription,
          operatingStructures: results[0].data,
          regions: results[1].data,
          statuses: results[2].data,
          types: results[3].data,
          verticals: results[4].data,
        });
      })
      .catch((err) => {
        if (err.response.status === 401 || err.response.status === 403) {
          signOut();
        } else if (err.response.status === 404) {
          if (isBrowser()) {
            navigate("/app/organizations");
          }
        } else {
          this.props.enqueueSnackbar(err.response.data.reason, {
            variant: "error",
          });
        }
      });
  }

  handleUpdate = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleRegionSelectUpdate = (event, values) => {
    this.setState({
      regionIds: values,
    });
  };

  handleVerticalSelectUpdate = (event, values) => {
    this.setState({
      verticalIds: values,
    });
  };

  selectFile = (event) => {
    const logos = event.target.files;
    if (logos.length !== 1) {
      this.props.enqueueSnackbar("Only 1 logo can be uploaded", {
        variant: "error",
      });
      return;
    }

    const formData = new FormData();
    formData.append("file", logos[0]);

    this.setState({ uploading: true });
    AuthToken();
    axios
      .post(`${process.env.GATSBY_API_URL}/organizations/${this.props.organizationId}/logo`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        this.setState({
          uploading: false,
          logoURL: response.data,
        });
        this.props.enqueueSnackbar("Logo updated", {
          variant: "success",
        });
      })
      .catch((err) => {
        this.setState({ uploading: false });
        if (err.response.status === 401 || err.response.status === 403) {
          signOut();
        } else {
          this.props.enqueueSnackbar(err.response.data.reason, {
            variant: "error",
          });
        }
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const data = {};
    data.hostname = this.state.hostname;
    data.name = this.state.organizationName;
    data.organizationTypeID = this.state.typeId;
    data.operatingStructureID = this.state.operatingStructureId;
    data.description = this.state.description;
    data.address = this.state.address;
    data.website = this.state.website;
    data.twitter = this.state.twitter;
    data.instagram = this.state.instagram;
    if (this.state.exchange === "Yes") {
      data.exchange = true;
    } else {
      data.exchange = false;
    }
    data.exchangeEmail = this.state.exchangeEmail;
    data.exchangeDescription = this.state.exchangeDescription;
    data.organizationStatusID = this.state.statusId;

    var regionIds = [];
    if (this.state.regionIds.length > 0) {
      this.state.regionIds.forEach((item) => {
        regionIds.push(item.id);
      });
    }
    data.regions = regionIds;

    var verticalIds = [];
    if (this.state.verticalIds.length > 0) {
      this.state.verticalIds.forEach((item) => {
        verticalIds.push(item.id);
      });
    }
    data.verticals = verticalIds;

    if (this.state.enabled === "Yes") {
      data.enabled = true;
    } else {
      data.enabled = false;
    }

    this.setState({ loading: true });
    AuthToken();
    axios
      .put(`${process.env.GATSBY_API_URL}/organizations/${this.props.organizationId}`, data)
      .then(() => {
        if (isBrowser()) {
          navigate("/app/organizations");
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        if (err.response.status === 403) {
          signOut();
        } else {
          this.props.enqueueSnackbar(err.message, { variant: "error" });
        }
      });
  };

  render() {
    return (
      <Layout>
        <Paper elevation={1} sx={{ p: 2 }}>
          <TitleNav title="Edit Organization Details" buttonLink="/app/organizations" buttonTitle="Organizations" />
          <Box sx={{ m: 2 }} />
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="hostname"
                  label="Subdomain"
                  name="hostname"
                  value={this.state.hostname}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="organizationName"
                  label="Name"
                  name="organizationName"
                  value={this.state.organizationName}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  multiline
                  minRows={5}
                  id="description"
                  label="Description"
                  name="description"
                  value={this.state.description}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  multiline
                  minRows={5}
                  id="address"
                  label="Address"
                  name="address"
                  value={this.state.address}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="enabled"
                  select
                  required
                  label="Enabled"
                  name="enabled"
                  value={this.state.enabled}
                  onChange={this.handleUpdate}
                  variant="outlined"
                  fullWidth
                >
                  {yesNo().map((option) => (
                    <MenuItem value={option.value} key={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  select
                  required
                  fullWidth
                  id="typeId"
                  label="Type"
                  name="typeId"
                  value={this.state.typeId}
                  onChange={this.handleUpdate}
                >
                  {this.state.types.map((option) => (
                    <MenuItem value={option.id} key={option.id}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  select
                  required
                  fullWidth
                  id="operatingStructureId"
                  label="Operating Structure"
                  name="operatingStructureId"
                  value={this.state.operatingStructureId}
                  onChange={this.handleUpdate}
                >
                  {this.state.operatingStructures.map((option) => (
                    <MenuItem value={option.id} key={option.id}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  select
                  required
                  fullWidth
                  id="statusId"
                  label="Status"
                  name="statusId"
                  value={this.state.statusId}
                  onChange={this.handleUpdate}
                >
                  {this.state.statuses.map((option) => (
                    <MenuItem value={option.id} key={option.id}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  id="regionIds"
                  options={this.state.regions}
                  getOptionLabel={(option) => option.label}
                  filterSelectedOptions
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  onChange={this.handleRegionSelectUpdate}
                  value={this.state.regionIds}
                  renderInput={(params) => <TextField {...params} variant="outlined" label="Regions" />}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  id="verticalIds"
                  options={this.state.verticals}
                  getOptionLabel={(option) => option.label}
                  filterSelectedOptions
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  onChange={this.handleVerticalSelectUpdate}
                  value={this.state.verticalIds}
                  renderInput={(params) => <TextField {...params} variant="outlined" label="Verticals" />}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="website"
                  label="Website URL"
                  name="website"
                  value={this.state.website}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="twitter"
                  label="Twitter Username"
                  name="twitter"
                  value={this.state.twitter}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="instagram"
                  label="Instagram Username"
                  name="instagram"
                  value={this.state.instagram}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  select
                  required
                  label="Global Workspace Connect"
                  name="exchange"
                  id="exchange"
                  value={this.state.exchange}
                  onChange={this.handleUpdate}
                  variant="outlined"
                  fullWidth
                >
                  {yesNo().map((option) => (
                    <MenuItem value={option.value} key={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="exchangeEmail"
                  label="Global Workspace Connect Email"
                  name="exchangeEmail"
                  value={this.state.exchangeEmail}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={6}>
                <Stack direction="row" spacing={2}>
                  <Card sx={{ maxWidth: 150 }}>
                    <CardMedia component="img" height="150" image={this.state.logoURL} />
                  </Card>
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="contained-button-file"
                    type="file"
                    onChange={this.selectFile}
                  />
                  <label htmlFor="contained-button-file">
                    <LoadingButton
                      fullWidth
                      loadingPosition="start"
                      startIcon={<FileUploadIcon />}
                      loading={this.state.uploading}
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      Upload Logo
                    </LoadingButton>
                  </label>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  multiline
                  minRows={5}
                  id="exchangeDescription"
                  label="Global Workspace Connect Description"
                  name="exchangeDescription"
                  value={this.state.exchangeDescription}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid container item xs={12} justifyContent="right">
                <LoadingButton
                  fullWidth
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  loading={this.state.loading}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Layout>
    );
  }
}

export default withSnackbar(EditOrganization);
