import * as React from "react";
import { DataGrid, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";
import axios from "axios";
import numeral from "numeraljs";
import moment from "moment";
import { withSnackbar, useSnackbar } from "notistack";
import Layout from "../Layout";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Stack from "@mui/material/Stack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import LinkIcon from "@mui/icons-material/Link";
import IconButton from "@mui/material/IconButton";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { navigate } from "gatsby";

import { isBrowser, signOut } from "../../helpers/LocalStorage";
import AuthToken from "../../helpers/AuthToken";

function PlaybookSectionAttachments(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);
  const [sectionTitle, setSectionTitle] = React.useState(true);

  const reloadContent = React.useCallback(() => {
    setIsLoading(true);
    AuthToken();
    axios
      .get(`${process.env.GATSBY_API_URL}/playbooks/${props.playbookId}/sections/${props.playbookSectionId}/attachments`)
      .then((response) => {
        setRows(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            props.enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          enqueueSnackbar("Unable to connect to the API", { variant: "error" });
        }
      });
  }, [props, enqueueSnackbar]);

  const enableAttachment = React.useCallback(
    (id) => () => {
      AuthToken();
      axios
        .patch(
          `${process.env.GATSBY_API_URL}/playbooks/${props.playbookId}/sections/${props.playbookSectionId}/attachments/${id}/enable`,
          {},
        )
        .then(() => {
          reloadContent();
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401 || err.response.status === 403) {
              signOut();
            } else {
              enqueueSnackbar(err.response.data.reason, {
                variant: "error",
              });
            }
          } else {
            enqueueSnackbar("Unable to connect to the API", { variant: "error" });
          }
        });
    },
    [enqueueSnackbar, props.playbookId, props.playbookSectionId, reloadContent],
  );

  const disableAttachment = React.useCallback(
    (id) => () => {
      AuthToken();
      axios
        .patch(
          `${process.env.GATSBY_API_URL}/playbooks/${props.playbookId}/sections/${props.playbookSectionId}/attachments/${id}/disable`,
          {},
        )
        .then(() => {
          reloadContent();
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401 || err.response.status === 403) {
              signOut();
            } else {
              enqueueSnackbar(err.response.data.reason, {
                variant: "error",
              });
            }
          } else {
            enqueueSnackbar("Unable to connect to the API", { variant: "error" });
          }
        });
    },
    [enqueueSnackbar, props.playbookId, props.playbookSectionId, reloadContent],
  );

  const deleteAttachment = React.useCallback(
    (id) => () => {
      AuthToken();
      axios
        .delete(
          `${process.env.GATSBY_API_URL}/playbooks/${props.playbookId}/sections/${props.playbookSectionId}/attachments/${id}`,
        )
        .then(() => {
          enqueueSnackbar("Deleted playbook section attachment", { variant: "success" });
          setRows((prevRows) => prevRows.filter((row) => row.id !== id));
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401 || err.response.status === 403) {
              signOut();
            } else {
              enqueueSnackbar(err.response.data.reason, {
                variant: "error",
              });
            }
          } else {
            enqueueSnackbar("Unable to connect to the API", { variant: "error" });
          }
        });
    },
    [enqueueSnackbar, props.playbookId, props.playbookSectionId],
  );

  const downloadAttachment = React.useCallback(
    (id) => () => {
      AuthToken();
      axios
        .get(
          `${process.env.GATSBY_API_URL}/playbooks/${props.playbookId}/sections/${props.playbookSectionId}/attachments/${id}`,
        )
        .then((response) => {
          if (isBrowser()) {
            navigate(response.data);
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401 || err.response.status === 403) {
              signOut();
            } else {
              enqueueSnackbar(err.response.data.reason, {
                variant: "error",
              });
            }
          } else {
            enqueueSnackbar("Unable to connect to the API", { variant: "error" });
          }
        });
    },
    [enqueueSnackbar, props.playbookId, props.playbookSectionId],
  );

  const selectFile = (event) => {
    const attachments = event.target.files;
    if (attachments.length !== 1) {
      enqueueSnackbar("Only 1 attachment can be uploaded at a time", { variant: "error" });
      return;
    }

    const formData = new FormData();
    formData.append("file", attachments[0]);
    setUploading(true);
    AuthToken();
    axios
      .post(
        `${process.env.GATSBY_API_URL}/playbooks/${props.playbookId}/sections/${props.playbookSectionId}/attachments`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        },
      )
      .then(() => {
        setUploading(false);
        reloadContent();
        enqueueSnackbar("Attachment uploaded", { variant: "success" });
      })
      .catch((err) => {
        setUploading(false);
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          enqueueSnackbar("Unable to connect to the API", { variant: "error" });
        }
      });
  };

  const columns = React.useMemo(
    () => [
      { field: "id", headerName: "ID", hide: true },
      {
        field: "enabled",
        width: 100,
        type: "boolean",
        renderHeader: () => <VisibilityIcon />,
      },
      { field: "originalName", headerName: "Attachment", width: 200 },
      {
        field: "url",
        headerName: "Link",
        width: 100,
        headerAlign: "center",
        align: "center",
        renderHeader: () => <LinkIcon />,
        renderCell: (params) => (
          <CopyToClipboard text={params.value}>
            <IconButton>
              <ContentPasteIcon />
            </IconButton>
          </CopyToClipboard>
        ),
      },
      {
        field: "size",
        headerName: "Size",
        width: 100,
        valueFormatter: (params) => {
          const valueFormatted = numeral(params.value).format("0 b");
          return `${valueFormatted}`;
        },
      },
      {
        field: "createdAt",
        headerName: "Created",
        width: 225,
        type: "dateTime",
        valueFormatter: (params) => {
          const valueFormatted = moment(params.value).format("llll");
          return `${valueFormatted}`;
        },
      },
      {
        field: "updatedAt",
        headerName: "Updated",
        width: 225,
        type: "dateTime",
        valueFormatter: (params) => {
          const valueFormatted = moment(params.value).format("llll");
          return `${valueFormatted}`;
        },
      },
      {
        field: "actions",
        type: "actions",
        width: 80,
        getActions: (params) => {
          if (params.row.enabled) {
            return [
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={deleteAttachment(params.id)}
                showInMenu
              />,
              <GridActionsCellItem
                icon={<VisibilityOffIcon />}
                label="Hide"
                onClick={disableAttachment(params.id)}
                showInMenu
              />,
              <GridActionsCellItem
                icon={<FileDownloadIcon />}
                label="Download"
                onClick={downloadAttachment(params.id)}
                showInMenu
              />,
            ];
          } else {
            return [
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={deleteAttachment(params.id)}
                showInMenu
              />,
              <GridActionsCellItem
                icon={<VisibilityIcon />}
                label="Show"
                onClick={enableAttachment(params.id)}
                showInMenu
              />,
              <GridActionsCellItem
                icon={<FileDownloadIcon />}
                label="Download"
                onClick={downloadAttachment(params.id)}
                showInMenu
              />,
            ];
          }
        },
      },
    ],
    [deleteAttachment, enableAttachment, disableAttachment, downloadAttachment],
  );

  React.useEffect(() => {
    setIsLoading(true);
    AuthToken();
    Promise.all([
      axios.get(`${process.env.GATSBY_API_URL}/playbooks/${props.playbookId}/sections/${props.playbookSectionId}`),
      axios.get(`${process.env.GATSBY_API_URL}/playbooks/${props.playbookId}/sections/${props.playbookSectionId}/attachments`),
    ])
      .then((results) => {
        setSectionTitle(results[0].data.heading);
        setRows(results[1].data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          enqueueSnackbar("Unable to connect to the API", { variant: "error" });
        }
      });
  }, [enqueueSnackbar, props.playbookId, props.playbookSectionId]);

  return (
    <Layout>
      <Paper
        elevation={1}
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography component="h1" variant="h4">
              {sectionTitle}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography component="h1" variant="h4" align="right">
              <Stack direction="row" spacing={2} justifyContent="right">
                <input style={{ display: "none" }} id="contained-button-file" type="file" onChange={selectFile} />
                <label htmlFor="contained-button-file">
                  <LoadingButton
                    loadingPosition="start"
                    startIcon={<FileUploadIcon />}
                    loading={uploading}
                    variant="contained"
                    color="primary"
                    component="span"
                  >
                    Upload Attachment
                  </LoadingButton>
                </label>
                <Button
                  variant="outlined"
                  startIcon={<ArrowBackIcon />}
                  href={`/app/playbooks/${props.playbookId}/sections`}
                >
                  Playbook Sections
                </Button>
              </Stack>
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ m: 1 }} />
        <div style={{ height: 575, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            disableColumnMenu
            loading={isLoading}
            components={{
              Toolbar: GridToolbar,
            }}
          />
        </div>
      </Paper>
    </Layout>
  );
}

export default withSnackbar(PlaybookSectionAttachments);
