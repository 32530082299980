import * as React from "react";
import numeral from "numeraljs";
import { withSnackbar } from "notistack";
import Grid from "@mui/material/Grid";
import DashboardCard from "./reports/DashboardCard";
import DashboardRedeemedCard from "./reports/DashboardRedeemedCard";
import Layout from "../Layout";
import axios from "axios";
import AuthToken from "../../helpers/AuthToken";
import { signOut } from "../../helpers/LocalStorage";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalOrganizations: 0,
      totalMembers: 0,
      totalInvites: 0,
      totalPerks: 0,
      totalEvents: 0,
      totalGlobalNetworks: 0,
      redeemedPerks30: 0,
      redeemedPerks6Months: 0,
      redeemedPerks: 0,
      orgRedeemedPerks30: 0,
      orgRedeemedPerks6Months: 0,
      orgRedeemedPerks: 0,
      currencyTotals: [],
      orgCurrencyTotals: [],
    };
  }

  componentDidMount() {
    AuthToken();
    axios
      .get(`${process.env.GATSBY_API_URL}/dashboard`)
      .then((response) => {
        this.setState({
          totalOrganizations: response.data.totalOrganizations,
          totalMembers: response.data.totalMembers,
          totalInvites: response.data.totalInvites,
          totalPerks: response.data.totalPerks,
          totalEvents: response.data.totalEvents,
          totalGlobalNetworks: response.data.totalGlobalNetworks,
          redeemedPerks30: response.data.redeemedPerksThirtyDays,
          redeemedPerks6Months: response.data.redeemedPerksSixMonths,
          redeemedPerks: response.data.redeemedPerks,
          orgRedeemedPerks30: response.data.orgRedeemedPerksThirtyDays,
          orgRedeemedPerks6Months: response.data.orgRedeemedPerksSixMonths,
          orgRedeemedPerks: response.data.orgRedeemedPerks,
          currencyTotals: response.data.currencyTotals,
          orgCurrencyTotals: response.data.orgCurrencyTotals,
        });
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            this.props.enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          this.props.enqueueSnackbar("Unable to connect to the API", { variant: "error" });
        }
      });
  }

  render() {
    const ganPerkAmounts = this.state.currencyTotals.map((link) => (
      <Grid item xs={6}>
        <DashboardCard
          title="Redeemed GAN Perks"
          subTitle={link.currency_symbol}
          metric={numeral(link.amount).format(0.0)}
        />
      </Grid>
    ));
    const orgPerkAmounts = this.state.orgCurrencyTotals.map((link) => (
      <Grid item xs={6}>
        <DashboardCard
          title="Redeemed Organization Perks"
          subTitle={link.currency_symbol}
          metric={numeral(link.amount).format(0.0)}
        />
      </Grid>
    ));
    return (
      <Layout>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <DashboardCard title="Organizations" metric={this.state.totalOrganizations} />
          </Grid>
          <Grid item xs={4}>
            <DashboardCard title="Users" metric={this.state.totalMembers} />
          </Grid>
          <Grid item xs={4}>
            <DashboardCard title="Invites" metric={this.state.totalInvites} />
          </Grid>
          <Grid item xs={4}>
            <DashboardCard title="Perks" metric={this.state.totalPerks} />
          </Grid>
          <Grid item xs={4}>
            <DashboardCard title="Events" metric={this.state.totalEvents} />
          </Grid>
          <Grid item xs={4}>
            <DashboardCard title="Global Networks" metric={this.state.totalGlobalNetworks} />
          </Grid>
          <Grid item xs={12}>
            <DashboardRedeemedCard
              title="Redeemed GAN Perks"
              thirtyDays={this.state.redeemedPerks30}
              sixMonths={this.state.redeemedPerks6Months}
              total={this.state.redeemedPerks}
            />
          </Grid>
          {ganPerkAmounts}
          <Grid item xs={12}>
            <DashboardRedeemedCard
              title="Redeemed Organization Perks"
              thirtyDays={this.state.orgRedeemedPerks30}
              sixMonths={this.state.orgRedeemedPerks6Months}
              total={this.state.orgRedeemedPerks}
            />
          </Grid>
          {orgPerkAmounts}
        </Grid>
      </Layout>
    );
  }
}
export default withSnackbar(Dashboard);
