import * as React from "react";
import axios from "axios";
import AuthToken from "../../helpers/AuthToken";
import Typography from "@mui/material/Typography";
import { withSnackbar } from "notistack";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { Editor } from "@tinymce/tinymce-react";
import Layout from "../Layout";
import TitleNav from "../TitleNav";

import { navigate } from "gatsby";
import { isBrowser, signOut } from "../../helpers/LocalStorage";

class EditPlaybookSection extends React.Component {
  constructor(props) {
    super(props);
    this.editorRef = React.createRef();
    this.state = {
      heading: ``,
      displayOrder: 0,
      content: ``,
      loading: false,
    };
  }

  componentDidMount() {
    AuthToken();
    axios
      .get(`${process.env.GATSBY_API_URL}/playbooks/${this.props.playbookId}/sections/${this.props.playbookSectionId}`)
      .then((response) => {
        const playbookSection = response.data;
        this.setState({
          heading: playbookSection.heading,
          displayOrder: playbookSection.displayOrder,
          content: playbookSection.content,
        });
      })
      .catch((err) => {
        if (err.response.status === 401 || err.response.status === 403) {
          signOut();
        } else if (err.response.status === 404) {
          if (isBrowser()) {
            navigate(`/app/playbooks/${this.props.playbookId}/sections`);
          }
        } else {
          this.props.enqueueSnackbar(err.response.data.reason, {
            variant: "error",
          });
        }
      });
  }

  handleUpdate = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const data = {};
    data.heading = this.state.heading;
    data.displayOrder = parseInt(this.state.displayOrder);
    data.content = this.editorRef.current.getContent();

    this.setState({ loading: true });
    AuthToken();
    axios
      .put(
        `${process.env.GATSBY_API_URL}/playbooks/${this.props.playbookId}/sections/${this.props.playbookSectionId}`,
        data,
      )
      .then(() => {
        this.props.enqueueSnackbar("Playbook section updated", {
          variant: "success",
        });
        if (isBrowser()) {
          navigate(`/app/playbooks/${this.props.playbookId}/sections`);
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        if (err.response.status === 401 || err.response.status === 403) {
          signOut();
        } else {
          this.props.enqueueSnackbar(err.response.data.reason, {
            variant: "error",
          });
        }
      });
  };

  render() {
    return (
      <Layout>
        <Paper elevation={1} sx={{ p: 2 }}>
          <TitleNav
            title="Edit Playbook Section"
            buttonLink={`/app/playbooks/${this.props.playbookId}/sections`}
            buttonTitle="Playbook Sections"
          />
          <Box sx={{ m: 2 }} />
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={9}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="heading"
                  label="Heading"
                  name="heading"
                  value={this.state.heading}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  variant="outlined"
                  fullWidth
                  id="displayOrder"
                  label="Display Order"
                  name="displayOrder"
                  value={this.state.displayOrder}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Content</Typography>
                <Editor
                  apiKey={`${process.env.GATSBY_TINY_MCE_API_KEY}`}
                  initialValue={this.state.content}
                  onInit={(evt, editor) => (this.editorRef.current = editor)}
                  init={{
                    height: 500,
                    menubar: false,
                    plugins: "lists link paste code",
                    toolbar:
                      "undo redo | bold italic | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | link | code",
                  }}
                />
                <Typography variant="caption">
                  Any fonts or colors set here will overide the fonts and colors in the member site
                </Typography>
              </Grid>
              <Grid container item xs={12} justifyContent="right">
                <LoadingButton
                  fullWidth
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  loading={this.state.loading}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Layout>
    );
  }
}

export default withSnackbar(EditPlaybookSection);
