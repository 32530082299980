import * as React from "react";
import { DataGrid, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";
import axios from "axios";
import { withSnackbar, useSnackbar } from "notistack";
import Layout from "../Layout";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import ToolTip from "@mui/material/Tooltip";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import moment from "moment";
import { navigate } from "gatsby";
import { isBrowser, signOut } from "../../helpers/LocalStorage";

import AuthToken from "../../helpers/AuthToken";

function ContentIntroductions() {
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const editContentIntroduction = React.useCallback(
    (id) => () => {
      if (isBrowser()) {
        navigate("/app/content-introductions/" + id);
      }
    },
    [],
  );

  const columns = React.useMemo(
    () => [
      { field: "id", headerName: "ID", hide: true },
      {
        field: "enabled",
        width: 100,
        type: "boolean",
        renderHeader: () => (
          <ToolTip title="Enabled">
            <CheckCircleOutlineIcon />
          </ToolTip>
        ),
      },
      { field: "contentType", headerName: "Section", width: 250 },
      { field: "title", headerName: "Title", width: 250 },
      {
        field: "createdAt",
        headerName: "Created",
        width: 225,
        type: "dateTime",
        valueFormatter: (params) => {
          const valueFormatted = moment(params.value).format("llll");
          return `${valueFormatted}`;
        },
      },
      {
        field: "updatedAt",
        headerName: "Updated",
        width: 225,
        type: "dateTime",
        valueFormatter: (params) => {
          const valueFormatted = moment(params.value).format("llll");
          return `${valueFormatted}`;
        },
      },
      {
        field: "actions",
        type: "actions",
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={editContentIntroduction(params.id)}
            showInMenu
          />,
        ],
      },
    ],
    [editContentIntroduction],
  );

  React.useEffect(() => {
    setIsLoading(true);
    AuthToken();
    axios
      .get(`${process.env.GATSBY_API_URL}/content-introductions`)
      .then((response) => {
        setRows(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          enqueueSnackbar("Unable to connect to the API", { variant: "error" });
        }
      });
  }, [enqueueSnackbar]);

  return (
    <Layout>
      <Paper
        elevation={1}
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography component="h1" variant="h4">
              Content Introductions
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ m: 1 }} />
        <div style={{ height: 575, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            disableColumnMenu
            loading={isLoading}
            components={{
              Toolbar: GridToolbar,
            }}
          />
        </div>
      </Paper>
    </Layout>
  );
}

export default withSnackbar(ContentIntroductions);
