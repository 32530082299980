import * as React from "react";
import { DataGrid, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";
import axios from "axios";
import { withSnackbar, useSnackbar } from "notistack";
import Layout from "../Layout";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ToolTip from "@mui/material/Tooltip";
import StarsIcon from "@mui/icons-material/Stars";
import TitleNav from "../TitleNav";

import moment from "moment";
import { navigate } from "gatsby";
import { isBrowser, signOut } from "../../helpers/LocalStorage";

import AuthToken from "../../helpers/AuthToken";

function PastMeetups() {
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const deletePastMeetup = React.useCallback(
    (id) => () => {
      AuthToken();
      axios
        .delete(`${process.env.GATSBY_API_URL}/past-meetups/${id}`)
        .then(() => {
          enqueueSnackbar("Deleted past meetup", { variant: "success" });
          setRows((prevRows) => prevRows.filter((row) => row.id !== id));
        })
        .catch((err) => {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        });
    },
    [enqueueSnackbar],
  );

  const editPastMeetup = React.useCallback(
    (id) => () => {
      if (isBrowser()) {
        navigate("/app/past-meetups/" + id);
      }
    },
    [],
  );

  const pastMeetupAttachments = React.useCallback(
    (id) => () => {
      if (isBrowser()) {
        navigate("/app/past-meetups/" + id + "/attachments");
      }
    },
    [],
  );

  const columns = React.useMemo(
    () => [
      { field: "id", headerName: "ID", hide: true },
      {
        field: "featured",
        width: 100,
        type: "boolean",
        renderHeader: () => (
          <ToolTip title="Featured">
            <StarsIcon />
          </ToolTip>
        ),
      },
      { field: "title", headerName: "Past Meetup", width: 200 },
      {
        field: "user",
        headerName: "Created By",
        width: 200,
        valueFormatter: (params) => {
          return params.value.firstName + " " + params.value.lastName;
        },
      },
      {
        field: "createdAt",
        headerName: "Created",
        width: 225,
        type: "dateTime",
        valueFormatter: (params) => {
          const valueFormatted = moment(params.value).format("llll");
          return `${valueFormatted}`;
        },
      },
      {
        field: "updatedAt",
        headerName: "Updated",
        width: 225,
        type: "dateTime",
        valueFormatter: (params) => {
          const valueFormatted = moment(params.value).format("llll");
          return `${valueFormatted}`;
        },
      },
      {
        field: "actions",
        type: "actions",
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem icon={<EditIcon />} label="Edit" onClick={editPastMeetup(params.id)} showInMenu />,
          <GridActionsCellItem
            icon={<AttachFileIcon />}
            label="Attachments"
            onClick={pastMeetupAttachments(params.id)}
            showInMenu
          />,
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={deletePastMeetup(params.id)} showInMenu />,
        ],
      },
    ],
    [editPastMeetup, pastMeetupAttachments, deletePastMeetup],
  );

  React.useEffect(() => {
    setIsLoading(true);
    AuthToken();
    axios
      .get(`${process.env.GATSBY_API_URL}/past-meetups`)
      .then((response) => {
        setRows(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          enqueueSnackbar("Unable to connect to the API", { variant: "error" });
        }
      });
  }, [enqueueSnackbar]);

  return (
    <Layout>
      <Paper
        elevation={1}
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <TitleNav title="Past Meetups" buttonLink="/app/past-meetups/add" buttonTitle="Past Meetup" addIcon />
        <Box sx={{ m: 1 }} />
        <div style={{ height: 575, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            disableColumnMenu
            loading={isLoading}
            components={{
              Toolbar: GridToolbar,
            }}
          />
        </div>
      </Paper>
    </Layout>
  );
}

export default withSnackbar(PastMeetups);
