import * as React from "react";
import axios from "axios";
import AuthToken from "../../helpers/AuthToken";
import { withSnackbar } from "notistack";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import MenuItem from "@mui/material/MenuItem";
import Layout from "../Layout";
import TitleNav from "../TitleNav";

import { navigate } from "gatsby";
import { isBrowser, signOut } from "../../helpers/LocalStorage";
import { yesNo } from "../../helpers/Lists";

class EditMember extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ``,
      firstName: ``,
      lastName: ``,
      role: ``,
      roles: [],
      enabled: `Yes`,
      organizationId: ``,
      organizations: [],
      loading: false,
    };
  }

  componentDidMount() {
    AuthToken();
    Promise.all([
      axios.get(`${process.env.GATSBY_API_URL}/organizations`),
      axios.get(`${process.env.GATSBY_API_URL}/lists/member-roles`),
      axios.get(`${process.env.GATSBY_API_URL}/members/${this.props.memberId}`),
    ])
      .then((results) => {
        const member = results[2].data;
        var enabled = "No";
        if (member.enabled) {
          enabled = "Yes";
        }

        this.setState({
          email: member.email,
          firstName: member.firstName,
          lastName: member.lastName,
          enabled: enabled,
          role: member.role,
          roles: results[1].data,
          organizationId: member.organizationID,
          organizations: results[0].data,
        });
      })
      .catch((err) => {
        if (err.response.status === 401 || err.response.status === 403) {
          signOut();
        } else {
          this.props.enqueueSnackbar(err.response.data.reason, {
            variant: "error",
          });
        }
      });
  }

  handleUpdate = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const data = {};
    data.firstName = this.state.firstName;
    data.lastName = this.state.lastName;
    data.email = this.state.email;
    if (this.state.enabled === "Yes") {
      data.enabled = true;
    } else {
      data.enabled = false;
    }
    data.role = this.state.role;

    this.setState({ loading: true });
    AuthToken();
    axios
      .put(`${process.env.GATSBY_API_URL}/members/${this.props.memberId}`, data)
      .then(() => {
        this.props.enqueueSnackbar("Member details updated", {
          variant: "success",
        });
        if (isBrowser()) {
          navigate("/app/members");
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        if (err.response.status === 401 || err.response.status === 403) {
          signOut();
        } else {
          this.props.enqueueSnackbar(err.response.data.reason, {
            variant: "error",
          });
        }
      });
  };

  render() {
    return (
      <Layout>
        <Paper elevation={1} sx={{ p: 2 }}>
          <TitleNav title="Edit Member Details" buttonLink="/app/members" buttonTitle="Members" />
          <Box sx={{ m: 2 }} />
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  name="firstName"
                  value={this.state.firstName}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  value={this.state.lastName}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  select
                  required
                  fullWidth
                  id="role"
                  label="Role"
                  name="role"
                  value={this.state.role}
                  onChange={this.handleUpdate}
                >
                  {this.state.roles.map((option) => (
                    <MenuItem value={option} key={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  select
                  required
                  fullWidth
                  id="enabled"
                  label="Enabled"
                  name="enabled"
                  value={this.state.enabled}
                  onChange={this.handleUpdate}
                >
                  {yesNo().map((option) => (
                    <MenuItem value={option.value} key={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  select
                  required
                  fullWidth
                  id="organizationId"
                  label="Organization"
                  name="organizationId"
                  value={this.state.organizationId}
                  onChange={this.handleUpdate}
                  disabled
                >
                  {this.state.organizations.map((option) => (
                    <MenuItem value={option.id} key={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid container item xs={12} justifyContent="right">
                <LoadingButton
                  fullWidth
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  loading={this.state.loading}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Layout>
    );
  }
}

export default withSnackbar(EditMember);
