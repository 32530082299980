import * as React from "react";
import axios from "axios";
import AuthToken from "../../helpers/AuthToken";
import { Typography } from "@mui/material";
import { withSnackbar } from "notistack";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { Editor } from "@tinymce/tinymce-react";
import MenuItem from "@mui/material/MenuItem";
import TitleNav from "../TitleNav";
import Layout from "../Layout";
import { navigate } from "gatsby";
import { isBrowser, signOut } from "../../helpers/LocalStorage";

class EditPlaybook extends React.Component {
  constructor(props) {
    super(props);
    this.editorRef = React.createRef();
    this.state = {
      title: ``,
      introduction: ``,
      topBlurb: ``,
      bottomBlurb: ``,
      typeId: ``,
      types: [],
      loading: false,
    };
  }

  componentDidMount() {
    AuthToken();
    Promise.all([
      axios.get(`${process.env.GATSBY_API_URL}/organization-types`),
      axios.get(`${process.env.GATSBY_API_URL}/playbooks/${this.props.playbookId}`),
    ])
      .then((results) => {
        const playbook = results[1].data;
        console.log(playbook);
        this.setState({
          title: playbook.title,
          introduction: playbook.introduction,
          topBlurb: playbook.topBlurb,
          bottomBlurb: playbook.bottomBlurb,
          typeId: playbook.organizationType.id,
          types: results[0].data,
        });
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else if (err.response.status === 404) {
            if (isBrowser()) {
              navigate("/app/playbooks");
            }
          } else {
            this.props.enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          this.props.enqueueSnackbar("Unable to connect to the API", { variant: "error" });
        }
      });
  }

  handleUpdate = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const data = {};
    data.title = this.state.title;
    data.introduction = this.editorRef.current.getContent();
    data.topBlurb = this.state.topBlurb;
    data.bottomBlurb = this.state.bottomBlurb;
    data.organizationTypeID = this.state.typeId;
    this.setState({ loading: true });
    AuthToken();
    axios
      .put(`${process.env.GATSBY_API_URL}/playbooks/${this.props.playbookId}`, data)
      .then(() => {
        this.props.enqueueSnackbar("Playbook updated", {
          variant: "success",
        });
        if (isBrowser()) {
          navigate("/app/playbooks");
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            this.props.enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          this.props.enqueueSnackbar("Unable to connect to the API", { variant: "error" });
        }
      });
  };

  render() {
    return (
      <Layout>
        <Paper elevation={1} sx={{ p: 2 }}>
          <TitleNav title="Edit Playbook" buttonLink="/app/playbooks" buttonTitle="Playbooks" />
          <Box sx={{ m: 2 }} />
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="title"
                  label="Title"
                  name="title"
                  value={this.state.title}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="topBlurb"
                  label="Top Blurb"
                  name="topBlurb"
                  value={this.state.topBlurb}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="bottomBlurb"
                  label="Bottom Blurb"
                  name="bottomBlurb"
                  value={this.state.bottomBlurb}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  select
                  required
                  fullWidth
                  id="typeId"
                  label="Type"
                  name="typeId"
                  value={this.state.typeId}
                  onChange={this.handleUpdate}
                >
                  {this.state.types.map((option) => (
                    <MenuItem value={option.id} key={option.id}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Introduction</Typography>
                <Editor
                  apiKey={`${process.env.GATSBY_TINY_MCE_API_KEY}`}
                  initialValue={this.state.introduction}
                  onInit={(evt, editor) => (this.editorRef.current = editor)}
                  init={{
                    height: 500,
                    menubar: false,
                    plugins: "lists link paste code",
                    toolbar:
                      "undo redo | bold italic | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | link | code",
                  }}
                />
                <Typography variant="caption">
                  Any fonts or colors set here will overide the fonts and colors in the member site
                </Typography>
              </Grid>
              <Grid container item xs={12} justifyContent="right">
                <LoadingButton
                  fullWidth
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  loading={this.state.loading}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Layout>
    );
  }
}

export default withSnackbar(EditPlaybook);
