import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import DownloadIcon from "@mui/icons-material/Download";
import { withSnackbar, useSnackbar } from "notistack";
import axios from "axios";

import AuthToken from "../helpers/AuthToken";
import { signOut } from "../helpers/LocalStorage";

const TitleAndCSVNav = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);

  function downloadCSV() {
    setLoading(true);
    AuthToken();
    axios
      .get(`${process.env.GATSBY_API_URL}/members/csv`, {})
      .then((res) => {
        setLoading(false);
        window.location.href = res.data;
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          this.props.enqueueSnackbar("Unable to connect to the API", { variant: "error" });
        }
      });
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Typography component="h1" variant="h4">
          {props.title}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography component="h1" variant="h4" align="right">
          <Stack direction="row" spacing={2} justifyContent="right">
            <LoadingButton
              loadingPosition="start"
              startIcon={<DownloadIcon />}
              loading={loading}
              variant="outlined"
              onClick={downloadCSV}
            >
              CSV
            </LoadingButton>
          </Stack>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default withSnackbar(TitleAndCSVNav);
