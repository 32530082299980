import * as React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import CategoryIcon from "@mui/icons-material/Category";
import MapIcon from "@mui/icons-material/Map";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import AppsIcon from "@mui/icons-material/Apps";
import DescriptionIcon from "@mui/icons-material/Description";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BusinessIcon from "@mui/icons-material/Business";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PublicIcon from "@mui/icons-material/Public";
import WorkIcon from "@mui/icons-material/Work";
import ComputerIcon from "@mui/icons-material/Computer";
import TitleIcon from "@mui/icons-material/Title";
import EventIcon from "@mui/icons-material/Event";
import Tooltip from "@mui/material/Tooltip";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import BookIcon from "@mui/icons-material/Book";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AddToDriveIcon from "@mui/icons-material/AddToDrive";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import SourceIcon from "@mui/icons-material/Source";
import DataThresholdingIcon from "@mui/icons-material/DataThresholding";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import SettingsIcon from "@mui/icons-material/Settings";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import AssessmentIcon from "@mui/icons-material/Assessment";

import { Link } from "gatsby";

export const mainListItems = (
  <div>
    <ListItem button component={Link} to="/app">
      <Tooltip title="Dashboard" placement="left" arrow>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Dashboard" />
    </ListItem>
    <ListItem button component={Link} to="/app/content-blocks">
      <Tooltip title="Content Blocks" placement="left" arrow>
        <ListItemIcon>
          <ViewAgendaIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Content Blocks" />
    </ListItem>
    <ListItem button component={Link} to="/app/content-introductions">
      <Tooltip title="Content Introductions" placement="left" arrow>
        <ListItemIcon>
          <SourceIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Content Introductions" />
    </ListItem>
    <ListItem button component={Link} to="/app/events">
      <Tooltip title="Events" placement="left" arrow>
        <ListItemIcon>
          <EventIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Events" />
    </ListItem>
    <ListItem button component={Link} to="/app/featured-news">
      <Tooltip title="Featured News" placement="left" arrow>
        <ListItemIcon>
          <NewspaperIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Featured News" />
    </ListItem>
    <ListItem button component={Link} to="/app/global-networks">
      <Tooltip title="Global Networks" placement="left" arrow>
        <ListItemIcon>
          <AltRouteIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Global Networks" />
    </ListItem>
    <ListItem button component={Link} to="/app/perks">
      <Tooltip title="Perks" placement="left" arrow>
        <ListItemIcon>
          <CardGiftcardIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Perks" />
    </ListItem>
    <ListItem button component={Link} to="/app/perks/redeemed">
      <Tooltip title="Redeemed Perks" placement="left" arrow>
        <ListItemIcon>
          <AssessmentIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Redeemed Perks" />
    </ListItem>
    <ListItem button component={Link} to="/app/startup-contents">
      <Tooltip title="Startup Contents" placement="left" arrow>
        <ListItemIcon>
          <ViewAgendaIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Startup Contents" />
    </ListItem>
  </div>
);

export const orgListItems = (
  <div>
    <ListItem button component={Link} to="/app/members/invites">
      <Tooltip title="Invites" placement="left" arrow>
        <ListItemIcon>
          <MarkunreadMailboxIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Member Invites" />
    </ListItem>
    <ListItem button component={Link} to="/app/members">
      <Tooltip title="Members" placement="left" arrow>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Members" />
    </ListItem>
    <ListItem button component={Link} to="/app/organizations">
      <Tooltip title="Organizations" placement="left" arrow>
        <ListItemIcon>
          <AppsIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Organizations" />
    </ListItem>
    <ListItem button component={Link} to="/app/organizations/perks">
      <Tooltip title="Organization Perks" placement="left" arrow>
        <ListItemIcon>
          <CardGiftcardIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Perks" />
    </ListItem>
    <ListItem button component={Link} to="/app/organizations/perks/redeemed">
      <Tooltip title="Redeemed Perks" placement="left" arrow>
        <ListItemIcon>
          <AssessmentIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Redeemed Perks" />
    </ListItem>
  </div>
);

export const insightAndDataListItems = (
  <div>
    <ListItem button component={Link} to="/app/data-reports">
      <Tooltip title="Data Reports" placement="left" arrow>
        <ListItemIcon>
          <DataThresholdingIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Data Reports" />
    </ListItem>
    <ListItem button component={Link} to="/app/founder-wellness">
      <Tooltip title="Founder Wellness" placement="left" arrow>
        <ListItemIcon>
          <SelfImprovementIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Founder Wellness" />
    </ListItem>
    <ListItem button component={Link} to="/app/gan-ventures">
      <Tooltip title="GAN Ventures" placement="left" arrow>
        <ListItemIcon>
          <MonetizationOnIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="GAN Ventures" />
    </ListItem>
    <ListItem button component={Link} to="/app/resources">
      <Tooltip title="Resources" placement="left" arrow>
        <ListItemIcon>
          <PermMediaIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Resources" />
    </ListItem>
    <ListItem button component={Link} to="/app/past-meetups">
      <Tooltip title="Past Meetups" placement="left" arrow>
        <ListItemIcon>
          <AddToDriveIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Past Meetups" />
    </ListItem>
    <ListItem button component={Link} to="/app/playbooks">
      <Tooltip title="Playbooks" placement="left" arrow>
        <ListItemIcon>
          <MenuBookIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Playbooks" />
    </ListItem>
    <ListItem button component={Link} to="/app/templates">
      <Tooltip title="Templates" placement="left" arrow>
        <ListItemIcon>
          <ContentCopyIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Templates" />
    </ListItem>
    <ListItem button component={Link} to="/app/white-papers">
      <Tooltip title="White Papers" placement="left" arrow>
        <ListItemIcon>
          <BookIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="White Papers" />
    </ListItem>
  </div>
);

export const settingsListItems = (
  <div>
    <ListItem button component={Link} to="/app/categories">
      <Tooltip title="Categories" placement="left" arrow>
        <ListItemIcon>
          <CategoryIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Categories" />
    </ListItem>
    <ListItem button component={Link} to="/app/continents">
      <Tooltip title="Continents" placement="left" arrow>
        <ListItemIcon>
          <PublicIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Continents" />
    </ListItem>
    <ListItem button component={Link} to="/app/currencies">
      <Tooltip title="Currencies" placement="left" arrow>
        <ListItemIcon>
          <AttachMoneyIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Currencies" />
    </ListItem>
    <ListItem button component={Link} to="/app/document-types">
      <Tooltip title="Document Types" placement="left" arrow>
        <ListItemIcon>
          <DescriptionIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Document Types" />
    </ListItem>
    <ListItem button component={Link} to="/app/funding-types" title="Funding Types">
      <Tooltip title="Funding Types" placement="left" arrow>
        <ListItemIcon>
          <CreditCardIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Funding Types" />
    </ListItem>
    <ListItem button component={Link} to="/app/industry-tags" title="Industry Tags">
      <Tooltip title="Industry Tags" placement="left" arrow>
        <ListItemIcon>
          <BusinessIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Industry Tags" />
    </ListItem>
    <ListItem button component={Link} to="/app/job-functions" title="Job Functions">
      <Tooltip title="Job Functions" placement="left" arrow>
        <ListItemIcon>
          <WorkIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Job Functions" />
    </ListItem>
    <ListItem button component={Link} to="/app/operating-structures" title="Operating Structures">
      <Tooltip title="Operating Structures" placement="left" arrow>
        <ListItemIcon>
          <AccountBalanceIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Operating Structures" />
    </ListItem>
    <ListItem button component={Link} to="/app/organization-statuses" title="Organization Statuses">
      <Tooltip title="Organization Statuses" placement="left" arrow>
        <ListItemIcon>
          <AccountTreeIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Organization Statuses" />
    </ListItem>
    <ListItem button component={Link} to="/app/organization-types" title="Organization Types">
      <Tooltip title="Organization Types" placement="left" arrow>
        <ListItemIcon>
          <ApartmentIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Organization Types" />
    </ListItem>
    <ListItem button component={Link} to="/app/regions" title="Regions">
      <Tooltip title="Regions" placement="left" arrow>
        <ListItemIcon>
          <MapIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Regions" />
    </ListItem>
    <ListItem button component={Link} to="/app/tags">
      <Tooltip title="Tags" placement="left" arrow>
        <ListItemIcon>
          <LocalOfferIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Tags" />
    </ListItem>
    <ListItem button component={Link} to="/app/technology-tags">
      <Tooltip title="Technology Tags" placement="left" arrow>
        <ListItemIcon>
          <ComputerIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Technology Tags" />
    </ListItem>
    <ListItem button component={Link} to="/app/titles">
      <Tooltip title="Titles" placement="left" arrow>
        <ListItemIcon>
          <TitleIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Titles" />
    </ListItem>
    <ListItem button component={Link} to="/app/verticals">
      <Tooltip title="Verticals" placement="left" arrow>
        <ListItemIcon>
          <CenterFocusStrongIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Verticals" />
    </ListItem>
  </div>
);

export const adminListItems = (
  <div>
    <ListItem button component={Link} to="/app/settings">
      <Tooltip title="Settings" placement="left" arrow>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Settings" />
    </ListItem>
    <ListItem button component={Link} to="/app/users">
      <Tooltip title="Users" placement="left" arrow>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Users" />
    </ListItem>
  </div>
);
