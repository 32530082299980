export const staffRoles = () => [
  { name: "Admin", value: "Admin" },
  { name: "Content Creator", value: "Content Creator" },
  { name: "Content Editor", value: "Content Editor" },
  { name: "User", value: "User" },
];

export const memberRoles = () => [
  { name: "Owner", value: "Owner" },
  { name: "Admin", value: "Admin" },
  { name: "Startup", value: "Startup" },
];

export const platforms = () => [
  { name: "Wistia", value: "Wistia" },
  { name: "Youtube", value: "Youtube" },
];

export const contentBlockSections = () => [{ name: "Dashboard", value: "Dashboard" }];
export const yesNo = () => [
  { name: "No", value: "No" },
  { name: "Yes", value: "Yes" },
];

export const mediaTypes = () => [
  { name: "Audio", value: "Audio" },
  { name: "Video", value: "Video" },
];

export const startupProgramStatus = () => [
  { name: "Alumni", value: "Alumni" },
  { name: "Cohort", value: "Cohort" },
];
