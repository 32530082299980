import * as React from "react";
import { DataGrid, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";
import axios from "axios";
import { withSnackbar, useSnackbar } from "notistack";
import Layout from "../Layout";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ToolTip from "@mui/material/Tooltip";
import StarsIcon from "@mui/icons-material/Stars";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ResourceNav from "../ResourceNav";

import moment from "moment";
import { navigate } from "gatsby";
import { isBrowser, signOut } from "../../helpers/LocalStorage";

import AuthToken from "../../helpers/AuthToken";

function Resources() {
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const deleteResource = React.useCallback(
    (id) => () => {
      AuthToken();
      axios
        .delete(`${process.env.GATSBY_API_URL}/resources/${id}`)
        .then(() => {
          enqueueSnackbar("Deleted resource", { variant: "success" });
          setRows((prevRows) => prevRows.filter((row) => row.id !== id));
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401 || err.response.status === 403) {
              signOut();
            } else {
              enqueueSnackbar(err.response.data.reason, {
                variant: "error",
              });
            }
          } else {
            enqueueSnackbar("Unable to connect to the API", { variant: "error" });
          }
        });
    },
    [enqueueSnackbar],
  );

  const downloadAttachment = React.useCallback(
    (id) => () => {
      AuthToken();
      axios
        .get(`${process.env.GATSBY_API_URL}/resources/attachment/${id}/download`)
        .then((response) => {
          if (isBrowser()) {
            navigate(response.data);
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401 || err.response.status === 403) {
              signOut();
            } else {
              enqueueSnackbar(err.response.data.reason, {
                variant: "error",
              });
            }
          } else {
            enqueueSnackbar("Unable to connect to the API", { variant: "error" });
          }
        });
    },
    [enqueueSnackbar],
  );

  const editEmbeddedResource = React.useCallback(
    (id) => () => {
      if (isBrowser()) {
        navigate("/app/resources/embedded/" + id);
      }
    },
    [],
  );

  const editAttachmentResource = React.useCallback(
    (id) => () => {
      if (isBrowser()) {
        navigate("/app/resources/attachment/" + id);
      }
    },
    [],
  );

  const columns = React.useMemo(
    () => [
      { field: "id", headerName: "ID", hide: true },
      {
        field: "featured",
        width: 100,
        type: "boolean",
        renderHeader: () => (
          <ToolTip title="Featured">
            <StarsIcon />
          </ToolTip>
        ),
      },
      { field: "title", headerName: "Resource", width: 200 },
      { field: "type", headerName: "Type", width: 200 },
      {
        field: "createdAt",
        headerName: "Created",
        width: 225,
        type: "dateTime",
        valueFormatter: (params) => {
          const valueFormatted = moment(params.value).format("llll");
          return `${valueFormatted}`;
        },
      },
      {
        field: "updatedAt",
        headerName: "Updated",
        width: 225,
        type: "dateTime",
        valueFormatter: (params) => {
          const valueFormatted = moment(params.value).format("llll");
          return `${valueFormatted}`;
        },
      },
      {
        field: "actions",
        type: "actions",
        width: 80,
        getActions: (params) => {
          if (params.row.type === "Attachment") {
            return [
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                onClick={editAttachmentResource(params.id)}
                showInMenu
              />,
              <GridActionsCellItem
                icon={<FileDownloadIcon />}
                label="Download"
                onClick={downloadAttachment(params.id)}
                showInMenu
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={deleteResource(params.id)}
                showInMenu
              />,
            ];
          } else {
            return [
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                onClick={editEmbeddedResource(params.id)}
                showInMenu
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={deleteResource(params.id)}
                showInMenu
              />,
            ];
          }
        },
      },
    ],
    [editAttachmentResource, editEmbeddedResource, deleteResource, downloadAttachment],
  );

  React.useEffect(() => {
    setIsLoading(true);
    AuthToken();
    axios
      .get(`${process.env.GATSBY_API_URL}/resources`)
      .then((response) => {
        setRows(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          enqueueSnackbar("Unable to connect to the API", { variant: "error" });
        }
      });
  }, [enqueueSnackbar]);

  return (
    <Layout>
      <Paper
        elevation={1}
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <ResourceNav
          title="Resources"
          addEmbedButtonLink="/app/resources/embedded/add"
          addEmbedButtonTitle="Video/Audio"
          addAttachmentButtonLink="/app/resources/attachment/add"
          addAttachmentButtonTitle="Attachment"
          backButtonLink="/app/resources"
          backButtonTitle="Resources"
          addIcon
        />
        <Box sx={{ m: 1 }} />
        <div style={{ height: 575, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            disableColumnMenu
            loading={isLoading}
            components={{
              Toolbar: GridToolbar,
            }}
          />
        </div>
      </Paper>
    </Layout>
  );
}

export default withSnackbar(Resources);
