import * as React from "react";
import { DataGrid, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";
import axios from "axios";
import { withSnackbar, useSnackbar } from "notistack";
import Layout from "../../Layout";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import { navigate } from "gatsby";
import { isBrowser, signOut } from "../../../helpers/LocalStorage";
import TitleNav from "../../TitleNav";
import AuthToken from "../../../helpers/AuthToken";

function OrganizationTypes() {
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const deleteOrganizationType = React.useCallback(
    (id) => () => {
      AuthToken();
      axios
        .delete(`${process.env.GATSBY_API_URL}/organization-types/${id}`)
        .then(() => {
          setRows((prevRows) => prevRows.filter((row) => row.id !== id));
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401 || err.response.status === 403) {
              signOut();
            } else {
              enqueueSnackbar(err.response.data.reason, {
                variant: "error",
              });
            }
          } else {
            enqueueSnackbar("Unable to connect to the API", { variant: "error" });
          }
        });
    },
    [enqueueSnackbar],
  );

  const editOrganizationType = React.useCallback(
    (id) => () => {
      if (isBrowser()) {
        navigate("/app/organization-types/" + id);
      }
    },
    [],
  );

  const columns = React.useMemo(
    () => [
      { field: "id", headerName: "ID", hide: true },
      { field: "label", headerName: "Organization Type", width: 300 },
      {
        field: "createdAt",
        headerName: "Created",
        width: 225,
        type: "dateTime",
        valueFormatter: (params) => {
          const valueFormatted = moment(params.value).format("llll");
          return `${valueFormatted}`;
        },
      },
      {
        field: "updatedAt",
        headerName: "Updated",
        width: 225,
        type: "dateTime",
        valueFormatter: (params) => {
          const valueFormatted = moment(params.value).format("llll");
          return `${valueFormatted}`;
        },
      },
      {
        field: "actions",
        type: "actions",
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem icon={<EditIcon />} label="Edit" onClick={editOrganizationType(params.id)} showInMenu />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={deleteOrganizationType(params.id)}
            showInMenu
          />,
        ],
      },
    ],
    [deleteOrganizationType, editOrganizationType],
  );

  React.useEffect(() => {
    setIsLoading(true);
    AuthToken();
    axios
      .get(`${process.env.GATSBY_API_URL}/organization-types`)
      .then((response) => {
        setRows(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          enqueueSnackbar("Unable to connect to the API", { variant: "error" });
        }
      });
  }, [enqueueSnackbar]);

  return (
    <Layout>
      <Paper
        elevation={1}
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <TitleNav
          title="Organization Types"
          buttonLink="/app/organization-types/add"
          buttonTitle="Organization Type"
          addIcon
        />
        <Box sx={{ m: 1 }} />
        <div style={{ height: 575, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            loading={isLoading}
            disableColumnMenu
            components={{
              Toolbar: GridToolbar,
            }}
          />
        </div>
      </Paper>
    </Layout>
  );
}

export default withSnackbar(OrganizationTypes);
