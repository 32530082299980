import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";

const ResourceNav = (props) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Typography component="h1" variant="h4">
          {props.title}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography component="h1" variant="h4" align="right">
          <Stack direction="row" spacing={2} justifyContent="right">
            <Button variant="outlined" startIcon={<AddIcon />} href={props.addEmbedButtonLink}>
              {props.addEmbedButtonTitle}
            </Button>
            <Button variant="outlined" startIcon={<AddIcon />} href={props.addAttachmentButtonLink}>
              {props.addAttachmentButtonTitle}
            </Button>
            <Button variant="outlined" startIcon={<ArrowBackIcon />} href={props.backButtonLink}>
              {props.backButtonTitle}
            </Button>
          </Stack>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ResourceNav;
