import * as React from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { yesNo } from "../../helpers/Lists";

class StartupCanViewSelect extends React.Component {
  handleUpdate = (event) => {
    this.props.selectChanged(event.target.value);
  };

  render() {
    return (
      <TextField
        id="startupCanView"
        select
        required={this.props.required}
        label="Startup Can View"
        name="startupCanView"
        value={this.props.canView}
        onChange={this.handleUpdate}
        variant="outlined"
        fullWidth
      >
        {yesNo().map((option) => (
          <MenuItem value={option.value} key={option.value}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
    );
  }
}

export default StartupCanViewSelect;
