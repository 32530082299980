import * as React from "react";
import { DataGrid, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";
import axios from "axios";
import numeral from "numeraljs";
import moment from "moment";
import { withSnackbar, useSnackbar } from "notistack";
import Layout from "../Layout";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Stack from "@mui/material/Stack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { navigate } from "gatsby";

import { isBrowser, signOut } from "../../helpers/LocalStorage";
import AuthToken from "../../helpers/AuthToken";

function PastMeetupAttachments(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);
  const [attachmentTitle, setAttachmentTitle] = React.useState(true);

  const reloadContent = () => {
    setIsLoading(true);
    AuthToken();
    axios
      .get(`${process.env.GATSBY_API_URL}/past-meetups/${props.pastMeetupId}`)
      .then((response) => {
        const pastMeetup = response.data;
        setAttachmentTitle(pastMeetup.title);
        setRows(pastMeetup.attachments);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.status === 401 || err.response.status === 403) {
          signOut();
        } else {
          enqueueSnackbar(err.response.data.reason, {
            variant: "error",
          });
        }
      });
  };

  const deletePastMeetupAttachment = React.useCallback(
    (id) => () => {
      AuthToken();
      axios
        .delete(`${process.env.GATSBY_API_URL}/past-meetups/${props.pastMeetupId}/attachments/${id}`)
        .then(() => {
          enqueueSnackbar("Deleted past meetup attachment", { variant: "success" });
          setRows((prevRows) => prevRows.filter((row) => row.id !== id));
        })
        .catch((err) => {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        });
    },
    [enqueueSnackbar, props.pastMeetupId],
  );

  const downloadAttachment = React.useCallback(
    (id) => () => {
      AuthToken();
      axios
        .get(`${process.env.GATSBY_API_URL}/past-meetups/${props.pastMeetupId}/attachments/${id}`)
        .then((response) => {
          if (isBrowser()) {
            navigate(response.data);
          }
        })
        .catch((err) => {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        });
    },
    [enqueueSnackbar, props.pastMeetupId],
  );

  const selectFile = (event) => {
    const attachments = event.target.files;
    if (attachments.length !== 1) {
      enqueueSnackbar("Only 1 attachment can be uploaded at a time", { variant: "error" });
      return;
    }

    const formData = new FormData();
    formData.append("file", attachments[0]);
    setUploading(true);
    AuthToken();
    axios
      .post(`${process.env.GATSBY_API_URL}/past-meetups/${props.pastMeetupId}/attachments`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(() => {
        setUploading(false);
        reloadContent();
        enqueueSnackbar("Attachment uploaded", { variant: "success" });
      })
      .catch((err) => {
        setUploading(false);
        if (err.response.status === 401 || err.response.status === 403) {
          signOut();
        } else {
          enqueueSnackbar(err.response.data.reason, {
            variant: "error",
          });
        }
      });
  };

  const columns = React.useMemo(
    () => [
      { field: "id", headerName: "ID", hide: true },
      { field: "originalName", headerName: "Attachment", width: 200 },
      {
        field: "size",
        headerName: "Size",
        width: 100,
        valueFormatter: (params) => {
          const valueFormatted = numeral(params.value).format("0 b");
          return `${valueFormatted}`;
        },
      },
      {
        field: "createdAt",
        headerName: "Created",
        width: 225,
        type: "dateTime",
        valueFormatter: (params) => {
          const valueFormatted = moment(params.value).format("llll");
          return `${valueFormatted}`;
        },
      },
      {
        field: "updatedAt",
        headerName: "Updated",
        width: 225,
        type: "dateTime",
        valueFormatter: (params) => {
          const valueFormatted = moment(params.value).format("llll");
          return `${valueFormatted}`;
        },
      },
      {
        field: "actions",
        type: "actions",
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={deletePastMeetupAttachment(params.id)}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<FileDownloadIcon />}
            label="Download"
            onClick={downloadAttachment(params.id)}
            showInMenu
          />,
        ],
      },
    ],
    [deletePastMeetupAttachment, downloadAttachment],
  );

  React.useEffect(() => {
    setIsLoading(true);
    AuthToken();
    axios
      .get(`${process.env.GATSBY_API_URL}/past-meetups/${props.pastMeetupId}`)
      .then((response) => {
        const pastMeetup = response.data;
        setAttachmentTitle(pastMeetup.title);
        setRows(pastMeetup.attachments);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          enqueueSnackbar("Unable to connect to the API", { variant: "error" });
        }
      });
  }, [enqueueSnackbar, props.pastMeetupId]);

  return (
    <Layout>
      <Paper
        elevation={1}
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography component="h1" variant="h4">
              {attachmentTitle}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography component="h1" variant="h4" align="right">
              <Stack direction="row" spacing={2} justifyContent="right">
                <input style={{ display: "none" }} id="contained-button-file" type="file" onChange={selectFile} />
                <label htmlFor="contained-button-file">
                  <LoadingButton
                    loadingPosition="start"
                    startIcon={<FileUploadIcon />}
                    loading={uploading}
                    variant="contained"
                    color="primary"
                    component="span"
                  >
                    Upload Attachment
                  </LoadingButton>
                </label>
                <Button variant="outlined" startIcon={<ArrowBackIcon />} href="/app/past-meetups">
                  Past Meetups
                </Button>
              </Stack>
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ m: 1 }} />
        <div style={{ height: 575, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            disableColumnMenu
            loading={isLoading}
            components={{
              Toolbar: GridToolbar,
            }}
          />
        </div>
      </Paper>
    </Layout>
  );
}

export default withSnackbar(PastMeetupAttachments);
