import * as React from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { mediaTypes } from "../../helpers/Lists";

class ResourceTypeSelect extends React.Component {
  handleUpdate = (event) => {
    this.props.selectChanged(event.target.value);
  };

  render() {
    return (
      <TextField
        id="resourceType"
        select
        required
        label="Resource Type"
        name="resourceType"
        value={this.props.resourceType}
        onChange={this.handleUpdate}
        variant="outlined"
        fullWidth
      >
        {mediaTypes().map((option) => (
          <MenuItem value={option.value} key={option.value}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
    );
  }
}

export default ResourceTypeSelect;
