import * as React from "react";
import axios from "axios";
import moment from "moment";
import AuthToken from "../../helpers/AuthToken";
import Typography from "@mui/material/Typography";
import { withSnackbar } from "notistack";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import { Editor } from "@tinymce/tinymce-react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import Layout from "../Layout";
import TitleNav from "../TitleNav";
import FeaturedSelect from "../widgets/FeaturedSelect";
import OrganizationCanViewSelect from "../widgets/OrganizationCanViewSelect";
import StartupCanViewSelect from "../widgets/StartupCanViewSelect";
import CategoriesAutocomplete from "../widgets/CategoriesAutocomplete";
import TagsAutocomplete from "../widgets/TagsAutocomplete";
import VerticalsAutocomplete from "../widgets/VerticalsAutocomplete";
import OperatingStructuresAutocomplete from "../widgets/OperatingStructuresAutocomplete";
import OrganizationTypesAutocomplete from "../widgets/OrganizationTypesAutocomplete";
import OrganizationStatusesAutocomplete from "../widgets/OrganizationStatusesAutocomplete";
import TitlesAutocomplete from "../widgets/TitlesAutocomplete";
import RegionsAutocomplete from "../widgets/RegionsAutocomplete";
import JobFunctionsAutocomplete from "../widgets/JobFunctionsAutocomplete";
import IndustryTagsAutocomplete from "../widgets/IndustryTagsAutocomplete";
import TechnologyTagsAutocomplete from "../widgets/TechnologyTagsAutocomplete";
import CountriesAutocomplete from "../widgets/CountriesAutocomplete";
import ContinentsAutocomplete from "../widgets/ContinentsAutocomplete";
import FundingTypesAutcomplete from "../widgets/FundingTypesAutocomplete";
import ProgramStatsusSelect from "../widgets/ProgramStatusSelect";
import OrganizationSelect from "../widgets/OrganizationSelect";

import { navigate } from "gatsby";
import { isBrowser, signOut } from "../../helpers/LocalStorage";

class AddEvent extends React.Component {
  constructor(props) {
    super(props);
    this.editorRef = React.createRef();
    this.state = {
      name: ``,
      label: ``,
      keywords: ``,
      organizationId: ``,
      email: ``,
      description: ``,
      agenda: ``,
      rsvpURL: ``,
      imageURL: ``,
      featured: `No`,
      startDate: moment(),
      endDate: moment(),
      organizationCanView: `Yes`,
      startupCanView: `Yes`,
      startupHeadquarterMaxDistance: 100,
      startupHeadquarterStates: ``,
      startupProgramStatus: ``,
      startupMinTotalFundingAmountRaised: undefined,
      startupMaxTotalFundingAmountRaised: undefined,
      startupMinAnnualRevenue: undefined,
      startupMaxAnnualRevenue: undefined,
      startupMinLastFundingAmount: undefined,
      startupMaxLastFundingAmount: undefined,
      startupMinSize: undefined,
      startupMaxSize: undefined,
      startupFoundedStart: undefined,
      startupFoundedEnd: undefined,
      startupLastRaisedStart: undefined,
      startupLastRaisedEnd: undefined,
      tagIds: [],
      categoryIds: [],
      organizationTypeIds: [],
      organizationVerticalIds: [],
      organizationOperatingStructureIds: [],
      organizationTagIds: [],
      organizationRegionIds: [],
      organizationStatusIds: [],
      startupTitleIds: [],
      startupJobFunctionIds: [],
      startupIndustryTagIds: [],
      startupTechnologyTagIds: [],
      startupHeadquarterCountryIds: [],
      startupHeadquarterContinentIds: [],
      startupFundingTypeIds: [],
      loading: false,
      uploading: false,
    };
  }

  componentDidMount() {
    AuthToken();
    axios
      .get(`${process.env.GATSBY_API_URL}/events/${this.props.eventId}`)
      .then((response) => {
        const event = response.data;
        this.setState({
          name: event.name,
          label: event.label,
          keywords: event.keywords,
          organizationId: event.organizationID,
          email: event.email,
          description: event.description,
          agenda: event.agenda,
          rsvpURL: event.rsvpURL,
          imageURL: event.image,
          featured: event.featured ? "Yes" : "No",
          startDate: moment.utc(event.startDate),
          endDate: moment.utc(event.endDate),
          organizationCanView: event.organizationCanView ? "Yes" : "No",
          startupCanView: event.startupCanView ? "Yes" : "No",
          startupHeadquarterMaxDistance: event.startupMaxDistance,
          startupHeadquarterStates: event.startupStates,
          startupProgramStatus: event.startupProgramStatus,
          startupMinTotalFundingAmountRaised: event.startupMinTotalFundingAmountRaised,
          startupMaxTotalFundingAmountRaised: event.startupMaxTotalFundingAmountRaised,
          startupMinAnnualRevenue: event.startupMinAnnualRevenue,
          startupMaxAnnualRevenue: event.startupMaxAnnualRevenue,
          startupMinLastFundingAmount: event.startupMinLastFundingAmount,
          startupMaxLastFundingAmount: event.startupMaxLastFundingAmount,
          startupMinSize: event.startupMinSize,
          startupMaxSize: event.startupMaxSize,
          startupFoundedStart: event.startupFoundedStart,
          startupFoundedEnd: event.startupFoundedEnd,
          startupLastRaisedStart: event.startupLastRaisedStart,
          startupLastRaisedEnd: event.startupLastRaisedEnd,
          tagIds: event.tags,
          categoryIds: event.categories,
          organizationTypeIds: event.organizationTypes,
          organizationVerticalIds: event.organizationVerticals,
          organizationOperatingStructureIds: event.organizationOperatingStructures,
          organizationTagIds: event.organizationTags,
          organizationRegionIds: event.organizationRegions,
          organizationStatusIds: event.organizationStatuses,
          startupTitleIds: event.startupTitles,
          startupJobFunctionIds: event.startupJobFunctions,
          startupIndustryTagIds: event.startupIndustryTags,
          startupTechnologyTagIds: event.startupTechnologyTags,
          startupHeadquarterCountryIds: event.startupCountries,
          startupHeadquarterContinentIds: event.startupContinents,
          startupFundingTypeIds: event.startupFundingTypes,
        });
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else if (err.response.status === 404) {
            if (isBrowser()) {
              navigate("/app/events");
            }
          } else {
            this.props.enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          console.log(err);
          this.props.enqueueSnackbar("Unable to connect to the API", { variant: "error" });
        }
      });
  }

  handleUpdate = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  organizationUpdate = (value) => {
    this.setState({
      organizationId: value,
    });
  };

  featured = (value) => {
    this.setState({
      featured: value,
    });
  };

  organizationCanView = (value) => {
    this.setState({
      organizationCanView: value,
    });
  };

  startupCanView = (value) => {
    this.setState({
      startupCanView: value,
    });
  };

  handleOrganizationTypesUpdate = (values) => {
    this.setState({
      anizationTypeIds: values,
    });
  };

  handleTagSelectUpdate = (values) => {
    this.setState({
      tagIds: values,
    });
  };

  handleCategorySelectUpdate = (values) => {
    this.setState({
      categoryIds: values,
    });
  };

  handleOrganizationVerticalsSelectUpdate = (values) => {
    this.setState({
      organizationVerticalIds: values,
    });
  };

  handleOrganizationOperatingStructuresSelectUpdate = (values) => {
    this.setState({
      organizationOperatingStructureIds: values,
    });
  };

  handleOrganizationTagsSelectUpdate = (values) => {
    this.setState({
      organizationTagIds: values,
    });
  };

  handleOrganizationRegionsSelectUpdate = (values) => {
    this.setState({
      organizationRegionIds: values,
    });
  };

  handleOrganizationStatusesSelectUpdate = (values) => {
    this.setState({
      organizationStatusIds: values,
    });
  };

  handleStartupTitleSelectUpdate = (values) => {
    this.setState({
      startupTitleIds: values,
    });
  };

  handleStartupJobFunctionSelectUpdate = (values) => {
    this.setState({
      startupJobFunctionIds: values,
    });
  };

  handleStartupIndustryTagSelectUpdate = (values) => {
    this.setState({
      startupIndustryTagIds: values,
    });
  };

  handleStartupTechnologyTagSelectUpdate = (values) => {
    this.setState({
      startupTechnologyTagIds: values,
    });
  };

  handleStartupCountrySelectUpdate = (values) => {
    this.setState({
      startupHeadquarterCountryIds: values,
    });
  };

  handleStartupContinentSelectUpdate = (values) => {
    this.setState({
      startupHeadquarterContinentIds: values,
    });
  };

  handleStartupFundingTypeSelectUpdate = (values) => {
    this.setState({
      startupFundingTypeIds: values,
    });
  };

  handleStartupProgramStatusSelectUpdate = (value) => {
    this.setState({
      startupProgramStatus: value,
    });
  };

  selectFile = (event) => {
    const images = event.target.files;
    if (images.length !== 1) {
      this.props.enqueueSnackbar("Only 1 image can be uploaded", { variant: "error" });
      return;
    }

    const formData = new FormData();
    formData.append("file", images[0]);

    this.setState({ uploading: true });
    AuthToken();
    axios
      .post(`${process.env.GATSBY_API_URL}/events/${this.props.eventId}/image`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        this.setState({ uploading: false });
        this.setState({ imageURL: response.data });
        this.props.enqueueSnackbar("Image updated", { variant: "success" });
      })
      .catch((err) => {
        this.setState({ uploading: false });
        if (err.response.status === 401 || err.response.status === 403) {
          signOut();
        } else {
          this.props.enqueueSnackbar(err.response.data.reason, {
            variant: "error",
          });
        }
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const data = {};
    data.name = this.state.name;
    data.label = this.state.label;
    data.keywords = this.state.keywords;
    if (this.state.organizationId !== "") {
      data.organizationID = this.state.organizationId;
    }
    data.email = this.state.email;
    data.description = this.state.description;
    data.agenda = this.editorRef.current.getContent();
    data.rsvpURL = this.state.rsvpURL;
    data.startDate = moment(this.state.startDate).format();
    data.endDate = moment(this.state.endDate).format();
    data.startupMaxDistance = this.state.startupHeadquarterMaxDistance;
    data.startupStates = this.state.startupHeadquarterStates;

    if (this.state.featured === "Yes") {
      data.featured = true;
    } else {
      data.featured = false;
    }

    if (this.state.organizationCanView === "Yes") {
      data.organizationCanView = true;
    } else {
      data.organizationCanView = false;
    }

    if (this.state.startupCanView === "Yes") {
      data.startupCanView = true;
    } else {
      data.startupCanView = false;
    }

    var tagIds = [];
    if (this.state.tagIds.length > 0) {
      this.state.tagIds.forEach((item) => {
        tagIds.push(item.id);
      });
    }
    data.tags = tagIds;

    var categoryIds = [];
    if (this.state.categoryIds.length > 0) {
      this.state.categoryIds.forEach((item) => {
        categoryIds.push(item.id);
      });
    }
    data.categories = categoryIds;

    var organizationTypeIds = [];
    if (this.state.organizationTypeIds.length > 0) {
      this.state.organizationTypeIds.forEach((item) => {
        organizationTypeIds.push(item.id);
      });
    }
    data.organizationTypes = organizationTypeIds;

    var organizationVerticalIds = [];
    if (this.state.organizationVerticalIds.length > 0) {
      this.state.organizationVerticalIds.forEach((item) => {
        organizationVerticalIds.push(item.id);
      });
    }
    data.organizationVerticals = organizationVerticalIds;

    var organizationOperatingStructureIds = [];
    if (this.state.organizationOperatingStructureIds.length > 0) {
      this.state.organizationOperatingStructureIds.forEach((item) => {
        organizationOperatingStructureIds.push(item.id);
      });
    }
    data.organizationOperatingStructures = organizationOperatingStructureIds;

    var organizationTagIds = [];
    if (this.state.organizationTagIds.length > 0) {
      this.state.organizationTagIds.forEach((item) => {
        organizationTagIds.push(item.id);
      });
    }
    data.organizationTags = organizationTagIds;

    var organizationRegionIds = [];
    if (this.state.organizationRegionIds.length > 0) {
      this.state.organizationRegionIds.forEach((item) => {
        organizationRegionIds.push(item.id);
      });
    }
    data.organizationRegions = organizationRegionIds;

    var organizationStatusIds = [];
    if (this.state.organizationStatusIds.length > 0) {
      this.state.organizationStatusIds.forEach((item) => {
        organizationStatusIds.push(item.id);
      });
    }
    data.organizationStatuses = organizationStatusIds;

    data.startupProgramStatus = this.state.startupProgramStatus;

    var startupTitleIds = [];
    if (this.state.startupTitleIds.length > 0) {
      this.state.startupTitleIds.forEach((item) => {
        startupTitleIds.push(item.id);
      });
    }
    data.startupTitles = startupTitleIds;

    var startupJobFunctionIds = [];
    if (this.state.startupJobFunctionIds.length > 0) {
      this.state.startupJobFunctionIds.forEach((item) => {
        startupJobFunctionIds.push(item.id);
      });
    }
    data.startupJobFunctions = startupJobFunctionIds;

    var startupIndustryTagIds = [];
    if (this.state.startupIndustryTagIds.length > 0) {
      this.state.startupIndustryTagIds.forEach((item) => {
        startupIndustryTagIds.push(item.id);
      });
    }
    data.startupIndustryTags = startupIndustryTagIds;

    var startupTechnologyTagIds = [];
    if (this.state.startupTechnologyTagIds.length > 0) {
      this.state.startupTechnologyTagIds.forEach((item) => {
        startupTechnologyTagIds.push(item.id);
      });
    }
    data.startupTechnologyTags = startupTechnologyTagIds;

    var startupHeadquarterCountryIds = [];
    if (this.state.startupHeadquarterCountryIds.length > 0) {
      this.state.startupHeadquarterCountryIds.forEach((item) => {
        startupHeadquarterCountryIds.push(item.id);
      });
    }
    data.startupCountries = startupHeadquarterCountryIds;

    var startupHeadquarterContinentIds = [];
    if (this.state.startupHeadquarterContinentIds.length > 0) {
      this.state.startupHeadquarterContinentIds.forEach((item) => {
        startupHeadquarterContinentIds.push(item.id);
      });
    }
    data.startupContinents = startupHeadquarterContinentIds;

    var startupFundingTypeIds = [];
    if (this.state.startupFundingTypeIds.length > 0) {
      this.state.startupFundingTypeIds.forEach((item) => {
        startupFundingTypeIds.push(item.id);
      });
    }
    data.startupFundingTypes = startupFundingTypeIds;

    data.startupMinAnnualRevenue = parseInt(this.state.startupMinAnnualRevenue);
    data.startupMaxAnnualRevenue = parseInt(this.state.startupMaxAnnualRevenue);

    data.startupMinTotalFundingAmountRaised = parseInt(this.state.startupMinTotalFundingAmountRaised);
    data.startupMaxTotalFundingAmountRaised = parseInt(this.state.startupMaxTotalFundingAmountRaised);

    data.startupMinLastFundingAmount = parseInt(this.state.startupMinLastFundingAmount);
    data.startupMaxLastFundingAmount = parseInt(this.state.startupMaxLastFundingAmount);

    data.startupMinSize = parseInt(this.state.startupMinSize);
    data.startupMaxSize = parseInt(this.state.startupMaxSize);

    data.startupLastRaisedStart = this.state.startupLastRaisedStart;
    data.startupLastRaisedEnd = this.state.startupLastRaisedEnd;

    data.startupFoundedStart = this.state.startupFoundedStart;
    data.startupFoundedEnd = this.state.startupFoundedEnd;

    this.setState({ loading: true });
    AuthToken();
    axios
      .put(`${process.env.GATSBY_API_URL}/events/${this.props.eventId}`, data)
      .then(() => {
        this.props.enqueueSnackbar("Event updated", {
          variant: "success",
        });
        if (isBrowser()) {
          navigate("/app/events/");
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            this.props.enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          this.props.enqueueSnackbar("Unable to connect to the API", { variant: "error" });
        }
      });
  };

  render() {
    return (
      <Layout>
        <Paper elevation={1} sx={{ p: 2 }}>
          <TitleNav title="Edit Event" buttonLink="/app/events" buttonTitle="Events" />
          <Box sx={{ m: 2 }} />
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="name"
                  label="Event"
                  name="name"
                  value={this.state.name}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="label"
                  label="Internal Name"
                  name="label"
                  value={this.state.label}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="keywords"
                  label="Keywords"
                  name="keywords"
                  value={this.state.keywords}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <OrganizationSelect
                  organizationId={this.state.organizationId}
                  selectChanged={this.organizationUpdate}
                />
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label="Start Date"
                    minDate={new Date()}
                    value={this.state.startDate}
                    onChange={(newValue) => {
                      this.setState({
                        startDate: newValue,
                      });
                    }}
                    renderInput={(params) => <TextField {...params} helperText={null} />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label="End Date"
                    minDate={new Date()}
                    value={this.state.endDate}
                    onChange={(newValue) => {
                      this.setState({
                        endDate: newValue,
                      });
                    }}
                    renderInput={(params) => <TextField {...params} helperText={null} />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleUpdate}
                  type="email"
                  helperText="The address to send notification email to when user rsvp"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="resvpURL"
                  label="RSVP URL"
                  name="rsvpURL"
                  helperText="Needs to be the full url, for example `https://gan.co/event`"
                  value={this.state.rsvpURL}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={4}>
                <FeaturedSelect required featured={this.state.featured} selectChanged={this.featured} />
              </Grid>
              <Grid item xs={4}>
                <OrganizationCanViewSelect
                  canView={this.state.organizationCanView}
                  selectChanged={this.organizationCanView}
                />
              </Grid>
              <Grid item xs={4}>
                <StartupCanViewSelect
                  required
                  canView={this.state.startupCanView}
                  selectChanged={this.startupCanView}
                />
              </Grid>
              <Grid item xs={12}>
                <CategoriesAutocomplete
                  categoryType="Event"
                  ids={this.state.categoryIds}
                  selectChanged={this.handleCategorySelectUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <TagsAutocomplete tagType="Event" ids={this.state.tagIds} selectChanged={this.handleTagSelectUpdate} />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  multiline
                  minRows={5}
                  id="description"
                  label="Description"
                  name="description"
                  value={this.state.description}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={4}>
                <Stack direction="row" spacing={2}>
                  <Card sx={{ maxWidth: 150 }}>
                    <CardMedia component="img" height="150" image={this.state.imageURL} />
                  </Card>
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="contained-button-file"
                    type="file"
                    onChange={this.selectFile}
                  />
                  <label htmlFor="contained-button-file">
                    <LoadingButton
                      fullWidth
                      loadingPosition="start"
                      startIcon={<FileUploadIcon />}
                      loading={this.state.uploading}
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      Upload Image
                    </LoadingButton>
                  </label>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Agenda</Typography>
                <Editor
                  apiKey={`${process.env.GATSBY_TINY_MCE_API_KEY}`}
                  initialValue={this.state.agenda}
                  onInit={(evt, editor) => (this.editorRef.current = editor)}
                  init={{
                    height: 500,
                    menubar: false,
                    plugins: "lists link paste code",
                    toolbar:
                      "undo redo | bold italic | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | link | code",
                  }}
                />
                <Typography variant="caption">
                  Any fonts or colors set here will overide the fonts and colors in the member site
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider>Organization Filters</Divider>
              </Grid>
              <Grid item xs={12}>
                <OrganizationTypesAutocomplete
                  ids={this.state.organizationTypeIds}
                  selectChanged={this.handleOrganizationTypesUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <VerticalsAutocomplete
                  ids={this.state.organizationVerticalIds}
                  selectChanged={this.handleOrgVerticalsSelectUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <OperatingStructuresAutocomplete
                  ids={this.state.organizationOperatingStructureIds}
                  selectChanged={this.handleOrganizationOperatingStructuresSelectUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <TagsAutocomplete
                  tagType="Event"
                  ids={this.state.organizationTagIds}
                  selectChanged={this.handleOrgTagsSelectUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <RegionsAutocomplete
                  ids={this.state.organizationRegionIds}
                  selectChanged={this.handleOrgRegionsSelectUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <OrganizationStatusesAutocomplete
                  ids={this.state.organizationStatusIds}
                  selectChanged={this.handleOrganizationStatusesSelectUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider>Startup Filters</Divider>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="startupHeadquarterMaxDistance"
                  label="Startup Headquarter Max Distance in Miles"
                  name="startupHeadquarterMaxDistance"
                  value={this.state.startupHeadquarterMaxDistance}
                  onChange={this.handleUpdate}
                  type="number"
                />
              </Grid>
              <Grid item xs={9}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="startupHeadquarterStates"
                  label="Startup Headquarter States"
                  name="startupHeadquarterStates"
                  value={this.state.startupHeadquarterStates}
                  onChange={this.handleUpdate}
                />
                <Typography variant="caption">Comma separated list of states.</Typography>
              </Grid>
              <Grid item xs={12}>
                <ProgramStatsusSelect
                  status={this.state.startupProgramStatus}
                  selectChanged={this.handleStartupProgramStatusSelectUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <TitlesAutocomplete
                  ids={this.state.startupTitleIds}
                  selectChanged={this.handleStartupTitleSelectUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <JobFunctionsAutocomplete
                  ids={this.state.startupJobFunctionIds}
                  selectChanged={this.handleStartupJobFunctionSelectUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <IndustryTagsAutocomplete
                  ids={this.state.startupIndustryTagIds}
                  selectChanged={this.handleStartupIndustryTagSelectUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <TechnologyTagsAutocomplete
                  ids={this.state.startupTechnologyTagIds}
                  selectChanged={this.handleStartupTechnologyTagSelectUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <CountriesAutocomplete
                  ids={this.state.startupHeadquarterCountryIds}
                  selectChanged={this.handleStartupCountrySelectUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <ContinentsAutocomplete
                  ids={this.state.startupHeadquarterContinentIds}
                  selectChanged={this.handleStartupContinentSelectUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <FundingTypesAutcomplete
                  ids={this.state.startupFundingTypeIds}
                  selectChanged={this.handleStartupFundingTypeSelectUpdate}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="startupMinAnnualRevenue"
                  label="Minimum Annual Revenue"
                  name="startupMinAnnualRevenue"
                  value={this.state.startupMinAnnualRevenue}
                  onChange={this.handleUpdate}
                  type="number"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="startupMaxAnnualRevenue"
                  label="Maximum Annual Revenue"
                  name="startupMaxAnnualRevenue"
                  value={this.state.startupMaxAnnualRevenue}
                  onChange={this.handleUpdate}
                  type="number"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="startupMinTotalFundingAmountRaised"
                  label="Minimum Total Funding Amount Raised"
                  name="startupMinTotalFundingAmountRaised"
                  value={this.state.startupMinTotalFundingAmountRaised}
                  onChange={this.handleUpdate}
                  type="number"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="startupMaxTotalFundingAmountRaised"
                  label="Maximum Total Funding Amount Raised"
                  name="startupMaxTotalFundingAmountRaised"
                  value={this.state.startupMaxTotalFundingAmountRaised}
                  onChange={this.handleUpdate}
                  type="number"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="startupMinLastFundingAmount"
                  label="Minimum Last Funding Amount Raised"
                  name="startupMinLastFundingAmount"
                  value={this.state.startupMinLastFundingAmount}
                  onChange={this.handleUpdate}
                  type="number"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="startupMaxLastFundingAmount"
                  label="Maximum Last Funding Amount Raised"
                  name="startupMaxLastFundingAmount"
                  value={this.state.startupMaxLastFundingAmount}
                  onChange={this.handleUpdate}
                  type="number"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="startupMinSize"
                  label="Minimum Team Size"
                  name="startupMinSize"
                  value={this.state.startupMinSize}
                  onChange={this.handleUpdate}
                  type="number"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="startupMaxSize"
                  label="Maximum Team Size"
                  name="startupMaxSize"
                  value={this.state.startupMaxSize}
                  onChange={this.handleUpdate}
                  type="number"
                />
              </Grid>
              <Grid item xs={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Last Raised Start Date"
                    minDate={new Date("1900-01-01")}
                    maxDate={new Date()}
                    value={this.state.startupLastRaisedStart}
                    onChange={(newValue) => {
                      this.setState({
                        startupLastRaisedStart: newValue,
                      });
                    }}
                    renderInput={(params) => <TextField {...params} helperText={null} />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Last Raised End Date"
                    minDate={new Date("1900-01-01")}
                    maxDate={new Date()}
                    value={this.state.startupLastRaisedEnd}
                    onChange={(newValue) => {
                      this.setState({
                        startupLastRaisedEnd: newValue,
                      });
                    }}
                    renderInput={(params) => <TextField {...params} helperText={null} />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Founded Start Date"
                    minDate={new Date("1900-01-01")}
                    maxDate={new Date()}
                    value={this.state.startupFoundedStart}
                    onChange={(newValue) => {
                      this.setState({
                        startupFoundedStart: newValue,
                      });
                    }}
                    renderInput={(params) => <TextField {...params} helperText={null} />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Founded End Date"
                    minDate={new Date("1900-01-01")}
                    maxDate={new Date()}
                    value={this.state.startupFoundedEnd}
                    onChange={(newValue) => {
                      this.setState({
                        startupFoundedEnd: newValue,
                      });
                    }}
                    renderInput={(params) => <TextField {...params} helperText={null} />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid container item xs={12} justifyContent="right">
                <LoadingButton
                  fullWidth
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  loading={this.state.loading}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Layout>
    );
  }
}

export default withSnackbar(AddEvent);
