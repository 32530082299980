import * as React from "react";
import axios from "axios";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import LinkIcon from "@mui/icons-material/Link";
import { useSnackbar } from "notistack";
import { signOut } from "../../helpers/LocalStorage";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import AuthToken from "../../helpers/AuthToken";

export default function LinkHubSpotOrganization(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const { id, saved } = props;
  const [domain, setDomain] = React.useState("");
  const [rows, setRows] = React.useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const linkOrganization = React.useCallback(
    (hubSpotId) => () => {
      AuthToken();
      axios
        .post(`${process.env.GATSBY_API_URL}/organizations/${id}/hubspot`, { id: hubSpotId })
        .then((response) => {
          setOpen(false);
          saved();
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401 || err.response.status === 403) {
              signOut();
            } else {
              enqueueSnackbar(err.response.data.reason, {
                variant: "error",
              });
            }
          } else {
            enqueueSnackbar("internal server error", {
              variant: "error",
            });
          }
        });
    },
    [enqueueSnackbar, id, saved],
  );

  const addNew = () => {
    AuthToken();
    axios
      .post(`${process.env.GATSBY_API_URL}/hubspot/companies`, { id, domain })
      .then(() => {
        setOpen(false);
        saved();
      })
      .catch(() => {
        enqueueSnackbar("Unable to add organization to HubSpot", {
          variant: "error",
        });
      });
  };

  const search = () => {
    AuthToken();
    axios
      .post(`${process.env.GATSBY_API_URL}/hubspot/companies/search`, { domain })
      .then((response) => {
        if (response.data.total > 0) {
          console.log(response.data.results);
          setRows(response.data.results);
        } else {
          enqueueSnackbar("No organizations meet your search criteria in HubSpot", {
            variant: "info",
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          console.error(err);
        }
      });
  };

  const columns = React.useMemo(
    () => [
      { field: "id", headerName: "HubSpot ID", width: 200 },
      {
        field: "domain",
        headerName: "Domain",
        width: 250,
        valueGetter: (params) => params.row.properties.domain,
      },
      { field: "name", headerName: "Organization", width: 250, valueGetter: (params) => params.row.properties.name },
      {
        field: "actions",
        type: "actions",
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem icon={<LinkIcon />} label="Link" onClick={linkOrganization(params.id)} />,
        ],
      },
    ],
    [linkOrganization],
  );

  return (
    <div>
      <IconButton color="error" onClick={handleClickOpen}>
        <LinkOffIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Link with HubSpot</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <Box sx={{ p: 2 }}>
            <TextField
              id="hostname"
              label="Search for Domain Name"
              fullWidth
              variant="outlined"
              onChange={(e) => setDomain(e.target.value)}
            />
          </Box>
          <div className="pt-2">
            <div style={{ height: 375, width: "100%" }}>
              <DataGrid rows={rows} columns={columns} disableColumnMenu />
            </div>
          </div>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={() => {
                addNew();
              }}
            >
              Add New
            </Button>
            <Button
              onClick={() => {
                search();
              }}
            >
              Search
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}
