import * as React from "react";
import { Typography, Button } from "@mui/material";

class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: ``,
    };
  }
  selectFile = (event) => {
    this.props.selectFile(event.target.files);
    if (event.target.files.length === 1) {
      const data = event.target.files[0];
      this.setState({
        title: data.name,
      });
    }
  };

  render() {
    return (
      <>
        <input style={{ display: "none" }} id="contained-button-file" type="file" onChange={this.selectFile} />
        <label htmlFor="contained-button-file">
          <Button variant="outlined" color="primary" component="span" fullWidth size="large">
            {this.props.buttonTitle}
          </Button>
        </label>
        <Typography variant="caption">{this.state.title}</Typography>
      </>
    );
  }
}

export default FileUpload;
