import * as React from "react";
import axios from "axios";
import AuthToken from "../../../helpers/AuthToken";
import { withSnackbar } from "notistack";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Layout from "../../Layout";
import TitleNav from "../../TitleNav";

import { navigate } from "gatsby";
import { isBrowser, signOut } from "../../../helpers/LocalStorage";
import CategoryTypeSelect from "../../widgets/CategoryTypeSelect";

class EditCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      label: ``,
      categoryType: ``,
    };
  }

  componentDidMount() {
    AuthToken();
    axios
      .get(`${process.env.GATSBY_API_URL}/categories/${this.props.categoryId}`)
      .then((response) => {
        this.setState({
          label: response.data.label,
          categoryType: response.data.type,
        });
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            this.props.enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
            if (isBrowser()) {
              navigate("/app/categories");
            }
          }
        } else {
          this.props.enqueueSnackbar("Unable to connect to the API", { variant: "error" });
        }
      });
  }

  handleUpdate = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  categoryType = (value) => {
    this.setState({
      categoryType: value,
    });
  };

  handleSubmit = (event) => {
    const data = {
      label: this.state.label,
      type: this.state.categoryType,
    };

    event.preventDefault();
    AuthToken();
    axios
      .put(`${process.env.GATSBY_API_URL}/categories/${this.props.categoryId}`, data)
      .then(() => {
        this.props.enqueueSnackbar("Category updated", {
          variant: "success",
        });
        if (isBrowser()) {
          navigate("/app/categories");
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            this.props.enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          this.props.enqueueSnackbar("Unable to connect to the API", { variant: "error" });
        }
      });
  };

  render() {
    return (
      <Layout>
        <Paper elevation={1} sx={{ p: 2 }}>
          <TitleNav title="Edit Category" buttonLink="/app/categories" buttonTitle="Categories" />
          <Box sx={{ m: 2 }} />
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="label"
                  label="Category"
                  name="label"
                  value={this.state.label}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <CategoryTypeSelect required categoryType={this.state.categoryType} selectChanged={this.categoryType} />
              </Grid>
              <Grid container item xs={12} justifyContent="right">
                <Button type="submit" variant="contained" color="primary">
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Layout>
    );
  }
}

export default withSnackbar(EditCategory);
