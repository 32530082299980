import * as React from "react";
import { DataGrid, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";
import axios from "axios";
import { withSnackbar, useSnackbar } from "notistack";
import Layout from "../Layout";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import TitleAndBackNav from "../TitleAndBackNav";

import moment from "moment";
import { navigate } from "gatsby";
import { isBrowser, signOut } from "../../helpers/LocalStorage";

import AuthToken from "../../helpers/AuthToken";

function GlobalNetworkQuestions(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [globalNetworkTitle, setGlobalNetworkTitle] = React.useState(true);

  const deleteQuestion = React.useCallback(
    (id) => () => {
      setIsLoading(true);
      AuthToken();
      axios
        .delete(`${process.env.GATSBY_API_URL}/global-networks/${props.globalNetworkId}/questions/${id}`)
        .then(() => {
          setIsLoading(false);
          enqueueSnackbar("Deleted global network question", { variant: "success" });
          setRows((prevRows) => prevRows.filter((row) => row.id !== id));
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response) {
            if (err.response.status === 401 || err.response.status === 403) {
              signOut();
            } else {
              enqueueSnackbar(err.response.data.reason, {
                variant: "error",
              });
            }
          } else {
            enqueueSnackbar("Unable to connect to the API", { variant: "error" });
          }
        });
    },
    [enqueueSnackbar, props.globalNetworkId],
  );

  const editQuestion = React.useCallback(
    (id) => () => {
      if (isBrowser()) {
        navigate(`/app/global-networks/${props.globalNetworkId}/questions/${id}`);
      }
    },
    [props.globalNetworkId],
  );

  const columns = React.useMemo(
    () => [
      { field: "id", headerName: "ID", hide: true },
      { field: "question", headerName: "Question", width: 250 },
      {
        field: "createdAt",
        headerName: "Created",
        width: 225,
        type: "dateTime",
        valueFormatter: (params) => {
          const valueFormatted = moment(params.value).format("llll");
          return `${valueFormatted}`;
        },
      },
      {
        field: "updatedAt",
        headerName: "Updated",
        width: 225,
        type: "dateTime",
        valueFormatter: (params) => {
          const valueFormatted = moment(params.value).format("llll");
          return `${valueFormatted}`;
        },
      },
      {
        field: "actions",
        type: "actions",
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem icon={<EditIcon />} label="Edit" onClick={editQuestion(params.id)} showInMenu />,
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={deleteQuestion(params.id)} showInMenu />,
        ],
      },
    ],
    [editQuestion, deleteQuestion],
  );

  React.useEffect(() => {
    setIsLoading(true);
    AuthToken();
    axios
      .get(`${process.env.GATSBY_API_URL}/global-networks/${props.globalNetworkId}/questions`)
      .then((response) => {
        const globalNetwork = response.data;
        setGlobalNetworkTitle(globalNetwork.name);
        setRows(globalNetwork.questions);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          enqueueSnackbar("Unable to connect to the API", { variant: "error" });
        }
      });
  }, [enqueueSnackbar, props.globalNetworkId]);

  return (
    <Layout>
      <Paper
        elevation={1}
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <TitleAndBackNav
          title={globalNetworkTitle}
          backButtonLink={`/app/global-networks`}
          backButtonTitle="Global Networks"
          buttonLink={`/app/global-networks/${props.globalNetworkId}/questions/add`}
          buttonTitle="Question"
        />
        <Box sx={{ m: 1 }} />
        <div style={{ height: 575, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            disableColumnMenu
            loading={isLoading}
            components={{
              Toolbar: GridToolbar,
            }}
          />
        </div>
      </Paper>
    </Layout>
  );
}

export default withSnackbar(GlobalNetworkQuestions);
