import * as React from "react";
import axios from "axios";
import AuthToken from "../../helpers/AuthToken";
import { withSnackbar } from "notistack";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import Layout from "../Layout";
import TitleNav from "../TitleNav";
import EnabledSelect from "../widgets/EnabledSelect";

import { navigate } from "gatsby";
import { isBrowser, signOut } from "../../helpers/LocalStorage";

class EditContentIntroduction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contentType: ``,
      title: ``,
      description: ``,
      topBlurb: ``,
      middleBlurb: ``,
      bottomBlurb: ``,
      enabled: `Yes`,
      loading: false,
      uploading: false,
    };
  }

  componentDidMount() {
    AuthToken();
    axios
      .get(`${process.env.GATSBY_API_URL}/content-introductions/${this.props.contentIntroductionId}`)
      .then((response) => {
        const contentIntroduction = response.data;
        this.setState({
          contentType: contentIntroduction.contentType,
          title: contentIntroduction.title,
          description: contentIntroduction.description,
          topBlurb: contentIntroduction.topBlurb,
          middleBlurb: contentIntroduction.middleBlurb,
          bottomBlurb: contentIntroduction.bottomBlurb,
          enabled: contentIntroduction.enabled ? "Yes" : "No",
        });
      })
      .catch((err) => {
        if (err.response.status === 401 || err.response.status === 403) {
          signOut();
        } else {
          this.props.enqueueSnackbar(err.response.data.reason, {
            variant: "error",
          });
        }
      });
  }

  handleUpdate = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  enabled = (value) => {
    this.setState({
      enabled: value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const data = {};
    data.title = this.state.title;
    data.description = this.state.description;
    data.topBlurb = this.state.topBlurb;
    data.middleBlurb = this.state.middleBlurb;
    data.bottomBlurb = this.state.bottomBlurb;

    if (this.state.enabled === "Yes") {
      data.enabled = true;
    } else {
      data.enabled = false;
    }

    this.setState({ loading: true });
    AuthToken();
    axios
      .put(`${process.env.GATSBY_API_URL}/content-introductions/${this.props.contentIntroductionId}`, data)
      .then(() => {
        this.props.enqueueSnackbar("Content introduction updated", {
          variant: "success",
        });
        if (isBrowser()) {
          navigate("/app/content-introductions");
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        if (err.response.status === 401 || err.response.status === 403) {
          signOut();
        } else {
          this.props.enqueueSnackbar(err.response.data.reason, {
            variant: "error",
          });
        }
      });
  };

  render() {
    return (
      <Layout>
        <Paper elevation={1} sx={{ p: 2 }}>
          <TitleNav
            title={this.state.contentType}
            buttonLink="/app/content-introductions"
            buttonTitle="Content Introductions"
          />
          <Box sx={{ m: 2 }} />
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="title"
                  label="Title"
                  name="title"
                  value={this.state.title}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  multiline
                  minRows={6}
                  id="description"
                  label="Description"
                  name="description"
                  value={this.state.description}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  multiline
                  minRows={3}
                  id="topBlurb"
                  label="Top Blurb"
                  name="topBlurb"
                  value={this.state.topBlurb}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  multiline
                  minRows={3}
                  id="middleBlurb"
                  label="Middle Blurb"
                  name="middleBlurb"
                  value={this.state.middleBlurb}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  multiline
                  minRows={3}
                  id="bottomBlurb"
                  label="Bottom Blurb"
                  name="bottomBlurb"
                  value={this.state.bottomBlurb}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={6}>
                <EnabledSelect required enabled={this.state.enabled} selectChanged={this.enabled} />
              </Grid>
              <Grid container item xs={12} justifyContent="right">
                <LoadingButton
                  fullWidth
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  loading={this.state.loading}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Layout>
    );
  }
}

export default withSnackbar(EditContentIntroduction);
