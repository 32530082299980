import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import { ThemeProvider } from "@mui/material/styles";
import Theme from "../../../theme";

function DashboardCard(props) {
  return (
    <ThemeProvider theme={Theme}>
      <Card>
        <CardContent>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h5" gutterBottom>
              {props.title}
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Typography variant="subtitle2" gutterBottom>
                  30 Days
                </Typography>
                <Typography variant="h3" color="primary" gutterBottom>
                  {props.thirtyDays}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2" gutterBottom>
                  6 Months
                </Typography>
                <Typography variant="h3" color="primary" gutterBottom>
                  {props.sixMonths}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2" gutterBottom>
                  Total
                </Typography>
                <Typography variant="h3" color="primary" gutterBottom>
                  {props.total}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </ThemeProvider>
  );
}

export default DashboardCard;
