import * as React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { withSnackbar, useSnackbar } from "notistack";
import Layout from "../Layout";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import moment from "moment";

import { signOut } from "../../helpers/LocalStorage";

import AuthToken from "../../helpers/AuthToken";

function RedeemedOrganizationPerks() {
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  function getFullName(params) {
    return `${params.row.member.firstName || ""} ${params.row.member.lastName || ""}`;
  }

  function getEmail(params) {
    return `${params.row.member.email || ""}`;
  }

  function getStartup(params) {
    if (params.row.member.startup) {
      return `${params.row.member.startup.name || "-"}`;
    } else {
      return "-";
    }
  }

  const columns = React.useMemo(
    () => [
      { field: "id", headerName: "ID", hide: true },
      { field: "name", headerName: "Perk", width: 200 },
      { field: "currencySymbol", headerName: "Currency", width: 100 },
      {
        field: "amount",
        headerName: "Amount",
        width: 150,
        type: "number",
      },
      {
        field: "organization",
        headerName: "Organization",
        width: 150,
        valueGetter: (params) => params.value.name,
      },
      {
        field: "member",
        headerName: "Startup",
        width: 250,
        valueGetter: getStartup,
      },
      {
        field: "fullName",
        headerName: "Full Name",
        width: 200,
        valueGetter: getFullName,
      },
      {
        field: "email",
        headerName: "Email",
        width: 200,
        valueGetter: getEmail,
      },
      {
        field: "createdAt",
        headerName: "Redeemed",
        width: 225,
        type: "dateTime",
        valueFormatter: (params) => {
          const valueFormatted = moment(params.value).format("llll");
          return `${valueFormatted}`;
        },
      },
    ],
    [],
  );

  React.useEffect(() => {
    setIsLoading(true);
    AuthToken();
    axios
      .get(`${process.env.GATSBY_API_URL}/organizations/perks/redeemed`)
      .then((response) => {
        setRows(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          enqueueSnackbar("Unable to connect to the API", { variant: "error" });
        }
      });
  }, [enqueueSnackbar]);

  return (
    <Layout>
      <Paper
        elevation={1}
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography component="h1" variant="h4">
              Redeemed Organization Perks
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ m: 1 }} />
        <div style={{ height: 575, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            disableColumnMenu
            loading={isLoading}
            components={{
              Toolbar: GridToolbar,
            }}
          />
        </div>
      </Paper>
    </Layout>
  );
}

export default withSnackbar(RedeemedOrganizationPerks);
