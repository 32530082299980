import * as React from "react";
import IconButton from "@mui/material/IconButton";
import LinkIcon from "@mui/icons-material/Link";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function UnlinkHubSpot(props) {
  const [open, setOpen] = React.useState(false);
  const { id, idToUnlink } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton color="success" onClick={handleClickOpen}>
        <LinkIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Unlink from HubSpot</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to remove the HubSpot link?</DialogContentText>
          <DialogActions>
            <Button onClick={handleClose}>No</Button>
            <Button
              onClick={() => {
                setOpen(false);
                idToUnlink(id);
              }}
            >
              Yes
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}
