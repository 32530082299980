import * as React from "react";
import { DataGrid, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";
import axios from "axios";
import { withSnackbar, useSnackbar } from "notistack";
import Layout from "../Layout";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ToolTip from "@mui/material/Tooltip";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HubIcon from "@mui/icons-material/Hub";
import LinkHubSpotMember from "../Dialogs/LinkHubSpotMember";
import UnlinkHubSpot from "../Dialogs/UnlinkHubSpot";
import moment from "moment";
import { navigate } from "gatsby";
import { isBrowser, signOut } from "../../helpers/LocalStorage";

import TitleAndCSVNav from "../TitleAndCSVNav";
import AuthToken from "../../helpers/AuthToken";

function Members() {
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const deleteMember = React.useCallback(
    (id) => () => {
      AuthToken();
      axios
        .delete(`${process.env.GATSBY_API_URL}/members/${id}`)
        .then(() => {
          setRows((prevRows) => prevRows.filter((row) => row.id !== id));
        })
        .catch((err) => {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        });
    },
    [enqueueSnackbar],
  );

  const editMember = React.useCallback(
    (id) => () => {
      if (isBrowser()) {
        navigate("/app/members/" + id);
      }
    },
    [],
  );

  const refreshData = React.useCallback(() => {
    setIsLoading(true);
    AuthToken();
    axios
      .get(`${process.env.GATSBY_API_URL}/members`)
      .then((response) => {
        setRows(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          enqueueSnackbar("Unable to connect to the API", { variant: "error" });
        }
      });
  }, [enqueueSnackbar]);

  const unlinkHubspot = React.useCallback(
    (idToUnlink) => {
      AuthToken();
      axios
        .delete(`${process.env.GATSBY_API_URL}/members/${idToUnlink}/hubspot`)
        .then(() => {
          setIsLoading(true);
          AuthToken();
          axios
            .get(`${process.env.GATSBY_API_URL}/members`)
            .then((response) => {
              setRows(response.data);
              setIsLoading(false);
            })
            .catch((err) => {
              setIsLoading(false);
              if (err.response) {
                if (err.response.status === 401 || err.response.status === 403) {
                  signOut();
                } else {
                  enqueueSnackbar(err.response.data.reason, {
                    variant: "error",
                  });
                }
              } else {
                enqueueSnackbar("Unable to connect to the API", { variant: "error" });
              }
            });
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401 || err.response.status === 403) {
              signOut();
            } else {
              enqueueSnackbar(err.response.data.reason, {
                variant: "error",
              });
            }
          } else {
            enqueueSnackbar("Unable to connect to the API", { variant: "error" });
          }
        });
    },
    [enqueueSnackbar],
  );

  const columns = React.useMemo(
    () => [
      { field: "id", headerName: "ID", hide: true },
      {
        field: "enabled",
        width: 100,
        type: "boolean",
        renderHeader: () => (
          <ToolTip title="Enabled">
            <CheckCircleIcon />
          </ToolTip>
        ),
      },
      {
        field: "hubSpotID",
        width: 100,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => {
          if (params.value) {
            return <UnlinkHubSpot id={params.id} idToUnlink={unlinkHubspot} />;
          } else {
            return <LinkHubSpotMember id={params.id} saved={refreshData} />;
          }
        },
        renderHeader: () => (
          <ToolTip title="HubSpot">
            <HubIcon />
          </ToolTip>
        ),
      },
      { field: "firstName", headerName: "First Name", width: 200 },
      { field: "lastName", headerName: "Last Name", width: 200 },
      {
        field: "email",
        headerName: "Email Address",
        width: 200,
        renderCell: (params) => (
          <Button href={`mailto://${params.value}`} variant="text" size="small" style={{ textTransform: "lowercase" }}>
            {params.value}
          </Button>
        ),
      },
      {
        field: "organization",
        headerName: "Organization",
        width: 150,
        valueGetter: (params) => params.value.name,
      },
      { field: "role", headerName: "Role", width: 120 },
      {
        field: "createdAt",
        headerName: "Created",
        width: 225,
        type: "dateTime",
        hide: true,
        valueFormatter: (params) => {
          const valueFormatted = moment(params.value).format("llll");
          return `${valueFormatted}`;
        },
      },
      {
        field: "updatedAt",
        headerName: "Updated",
        width: 225,
        type: "dateTime",
        hide: true,
        valueFormatter: (params) => {
          const valueFormatted = moment(params.value).format("llll");
          return `${valueFormatted}`;
        },
      },
      {
        field: "actions",
        type: "actions",
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem icon={<EditIcon />} label="Edit" onClick={editMember(params.id)} showInMenu />,
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={deleteMember(params.id)} showInMenu />,
        ],
      },
    ],
    [deleteMember, editMember, unlinkHubspot, refreshData],
  );

  React.useEffect(() => {
    setIsLoading(true);
    AuthToken();
    axios
      .get(`${process.env.GATSBY_API_URL}/members`)
      .then((response) => {
        setRows(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          enqueueSnackbar("Unable to connect to the API", { variant: "error" });
        }
      });
  }, [enqueueSnackbar]);

  return (
    <Layout>
      <Paper
        elevation={1}
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TitleAndCSVNav title="Members" />
          </Grid>
        </Grid>

        <Box sx={{ m: 1 }} />
        <div style={{ height: 575, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            loading={isLoading}
            disableColumnMenu
            components={{
              Toolbar: GridToolbar,
            }}
          />
        </div>
      </Paper>
    </Layout>
  );
}

export default withSnackbar(Members);
