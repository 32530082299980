import * as React from "react";
import axios from "axios";
import moment from "moment";
import AuthToken from "../../helpers/AuthToken";
import Typography from "@mui/material/Typography";
import { withSnackbar } from "notistack";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Divider from "@mui/material/Divider";
import { Editor } from "@tinymce/tinymce-react";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import Layout from "../Layout";
import TitleNav from "../TitleNav";
import FeaturedSelect from "../widgets/FeaturedSelect";
import OrganizationCanViewSelect from "../widgets/OrganizationCanViewSelect";
import StartupCanViewSelect from "../widgets/StartupCanViewSelect";
import CategoriesAutocomplete from "../widgets/CategoriesAutocomplete";
import TagsAutocomplete from "../widgets/TagsAutocomplete";
import EnabledSelect from "../widgets/EnabledSelect";
import AcceptingRequestSelect from "../widgets/AcceptingRequestSelect";
import VerticalsAutocomplete from "../widgets/VerticalsAutocomplete";
import OperatingStructuresAutocomplete from "../widgets/OperatingStructuresAutocomplete";
import OrganizationTypesAutocomplete from "../widgets/OrganizationTypesAutocomplete";
import OrganizationStatusesAutocomplete from "../widgets/OrganizationStatusesAutocomplete";
import TitlesAutocomplete from "../widgets/TitlesAutocomplete";
import RegionsAutocomplete from "../widgets/RegionsAutocomplete";
import JobFunctionsAutocomplete from "../widgets/JobFunctionsAutocomplete";
import IndustryTagsAutocomplete from "../widgets/IndustryTagsAutocomplete";
import TechnologyTagsAutocomplete from "../widgets/TechnologyTagsAutocomplete";
import CountriesAutocomplete from "../widgets/CountriesAutocomplete";
import ContinentsAutocomplete from "../widgets/ContinentsAutocomplete";
import FundingTypesAutcomplete from "../widgets/FundingTypesAutocomplete";
import ProgramStatsusSelect from "../widgets/ProgramStatusSelect";
import ConnectionWorkflowTierSelect from "../widgets/ConnectionWorkflowTierSelect";

import { navigate } from "gatsby";
import { isBrowser, signOut } from "../../helpers/LocalStorage";

class EditGlobalNetwork extends React.Component {
  constructor(props) {
    super(props);
    this.editorRef = React.createRef();
    this.state = {
      name: ``,
      keywords: ``,
      email: ``,
      image: ``,
      acceptingRequests: `Yes`,
      enabled: `Yes`,
      featured: `No`,
      connectionWorkflowTier: `Direct Connection`,
      shortDescription: ``,
      longDescription: ``,
      address: ``,
      organizationCanView: `Yes`,
      startupCanView: `Yes`,
      startupHeadquarterMaxDistance: 100,
      startupHeadquarterStates: ``,
      startupProgramStatus: ``,
      startupMinTotalFundingAmountRaised: ``,
      startupMaxTotalFundingAmountRaised: ``,
      startupMinAnnualRevenue: ``,
      startupMaxAnnualRevenue: ``,
      startupMinLastFundingAmount: ``,
      startupMaxLastFundingAmount: ``,
      startupMinSize: ``,
      startupMaxSize: ``,
      startupFoundedStart: null,
      startupFoundedEnd: null,
      startupLastRaisedStart: null,
      startupLastRaisedEnd: null,
      tags: [],
      tagIds: [],
      categoryIds: [],
      orgOrganizationTypeIds: [],
      orgVerticalIds: [],
      orgOperatingStructureIds: [],
      orgTagIds: [],
      orgRegionIds: [],
      orgOrganizationStatusIds: [],
      startupTitleIds: [],
      startupJobFunctionIds: [],
      startupIndustryTagIds: [],
      startupTechnologyTagIds: [],
      startupHeadQuarterCountryIds: [],
      startupHeadQuarterContinentIds: [],
      startupFundingTypeIds: [],
      loading: false,
      uploading: false,
    };
  }

  componentDidMount() {
    AuthToken();
    axios
      .get(`${process.env.GATSBY_API_URL}/global-networks/${this.props.globalNetworkId}`)
      .then((response) => {
        const globalNetwork = response.data;
        this.setState({
          name: globalNetwork.name,
          keywords: globalNetwork.keywords,
          email: globalNetwork.email,
          image: globalNetwork.image,
          acceptingRequests: globalNetwork.acceptingRequests ? "Yes" : "No",
          enabled: globalNetwork.enabled ? "Yes" : "No",
          featured: globalNetwork.featured ? "Yes" : "No",
          connectionWorkflowTier: globalNetwork.connectionWorkflowTier,
          shortDescription: globalNetwork.shortDescription,
          longDescription: globalNetwork.longDescription,
          address: globalNetwork.address,
          startupHeadquarterMaxDistance: globalNetwork.startupMaxDistance,
          startupHeadquarterStates: globalNetwork.startupStates,
          startupCanView: globalNetwork.startupCanView ? "Yes" : "No",
          organizationCanView: globalNetwork.organizationCanView ? "Yes" : "No",
          startupProgramStatus: globalNetwork.startupProgramStatus,
          startupMinTotalFundingAmountRaised: globalNetwork.startupMinTotalFundingAmountRaised,
          startupMaxTotalFundingAmountRaised: globalNetwork.startupMaxTotalFundingAmountRaised,
          startupMinAnnualRevenue: globalNetwork.startupMinAnnualRevenue,
          startupMaxAnnualRevenue: globalNetwork.startupMaxAnnualRevenue,
          startupMinLastFundingAmount: globalNetwork.startupMinLastFundingAmount,
          startupMaxLastFundingAmount: globalNetwork.startupMaxLastFundingAmount,
          startupMinSize: globalNetwork.startupMinSize,
          startupMaxSize: globalNetwork.startupMaxSize,
          startupFoundedStart: globalNetwork.startupFoundedStart ? globalNetwork.startupFoundedStart : null,
          startupFoundedEnd: globalNetwork.startupFoundedEnd ? globalNetwork.startupFoundedEnd : null,
          startupLastRaisedStart: globalNetwork.startupLastRaisedStart ? globalNetwork.startupLastRaisedStart : null,
          startupLastRaisedEnd: globalNetwork.startupLastRaisedEnd ? globalNetwork.startupLastRaisedEnd : null,
          tagIds: globalNetwork.tags,
          categoryIds: globalNetwork.categories,
          orgOrganizationTypeIds: globalNetwork.organizationTypes,
          orgVerticalIds: globalNetwork.organizationVerticals,
          orgOperatingStructureIds: globalNetwork.organizationOperatingStructures,
          orgTagIds: globalNetwork.organizationTags,
          orgRegionIds: globalNetwork.organizationRegions,
          orgOrganizationStatusIds: globalNetwork.organizationStatuses,
          startupTitleIds: globalNetwork.startupTitles,
          startupJobFunctionIds: globalNetwork.startupJobFunctions,
          startupIndustryTagIds: globalNetwork.startupIndustryTags,
          startupTechnologyTagIds: globalNetwork.startupTechnologyTags,
          startupHeadQuarterCountryIds: globalNetwork.startupCountries,
          startupHeadQuarterContinentIds: globalNetwork.startupContinents,
          startupFundingTypeIds: globalNetwork.startupFundingTypes,
        });
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            this.props.enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          this.props.enqueueSnackbar("unable to connect to API", {
            variant: "error",
          });
        }
      });
  }

  handleUpdate = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  connectionWorkflowTier = (value) => {
    this.setState({
      connectionWorkflowTier: value,
    });
  };

  acceptingRequests = (value) => {
    this.setState({
      featured: value,
    });
  };

  enabled = (value) => {
    this.setState({
      enabled: value,
    });
  };

  featured = (value) => {
    this.setState({
      featured: value,
    });
  };

  organizationCanView = (value) => {
    this.setState({
      organizationCanView: value,
    });
  };

  startupCanView = (value) => {
    this.setState({
      startupCanView: value,
    });
  };

  handleOrgOrganizationTypesUpdate = (values) => {
    this.setState({
      orgOrganizationTypeIds: values,
    });
  };

  handleTagSelectUpdate = (values) => {
    this.setState({
      tagIds: values,
    });
  };

  handleCategorySelectUpdate = (values) => {
    this.setState({
      categoryIds: values,
    });
  };

  handleOrgVerticalsSelectUpdate = (values) => {
    this.setState({
      orgVerticalIds: values,
    });
  };

  handleOrgOperatingStructuresSelectUpdate = (values) => {
    this.setState({
      orgOperatingStructureIds: values,
    });
  };

  handleOrgTagsSelectUpdate = (values) => {
    this.setState({
      orgTagIds: values,
    });
  };

  handleOrgRegionsSelectUpdate = (values) => {
    this.setState({
      orgRegionIds: values,
    });
  };

  handleOrgOrganizationStatusesSelectUpdate = (values) => {
    this.setState({
      orgOrganizationStatusIds: values,
    });
  };

  handleStartupTitleSelectUpdate = (values) => {
    this.setState({
      startupTitleIds: values,
    });
  };

  handleStartupJobFunctionSelectUpdate = (values) => {
    this.setState({
      startupJobFunctionIds: values,
    });
  };

  handleStartupIndustryTagSelectUpdate = (values) => {
    this.setState({
      startupIndustryTagIds: values,
    });
  };

  handleStartupTechnologyTagSelectUpdate = (values) => {
    this.setState({
      startupTechnologyTagIds: values,
    });
  };

  handleStartupCountrySelectUpdate = (values) => {
    this.setState({
      startupHeadQuarterCountryIds: values,
    });
  };

  handleStartupContinentSelectUpdate = (values) => {
    this.setState({
      startupHeadQuarterContinentIds: values,
    });
  };

  handleStartupFundingTypeSelectUpdate = (values) => {
    this.setState({
      startupFundingTypeIds: values,
    });
  };

  handleStartupProgramStatusSelectUpdate = (value) => {
    this.setState({
      startupProgramStatus: value,
    });
  };

  selectFile = (event) => {
    const images = event.target.files;
    if (images.length !== 1) {
      this.props.enqueueSnackbar("Only 1 image can be uploaded", { variant: "error" });
      return;
    }

    const formData = new FormData();
    formData.append("file", images[0]);

    this.setState({ uploading: true });
    AuthToken();
    axios
      .post(`${process.env.GATSBY_API_URL}/global-networks/${this.props.globalNetworkId}/image`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        this.setState({ uploading: false });
        this.setState({ image: response.data });
        this.props.enqueueSnackbar("Image updated", { variant: "success" });
      })
      .catch((err) => {
        this.setState({ uploading: false });
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            this.props.enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          this.props.enqueueSnackbar("unable to connect to API", {
            variant: "error",
          });
        }
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const data = {};
    data.name = this.state.name;
    data.keywords = this.state.keywords;
    data.email = this.state.email;
    data.connectionWorkflowTier = this.state.connectionWorkflowTier;

    if (this.state.acceptingRequests === "Yes") {
      data.acceptingRequests = true;
    } else {
      data.acceptingRequests = false;
    }

    if (this.state.enabled === "Yes") {
      data.enabled = true;
    } else {
      data.enabled = false;
    }

    if (this.state.featured === "Yes") {
      data.featured = true;
    } else {
      data.featured = false;
    }

    data.shortDescription = this.state.shortDescription;
    data.longDescription = this.editorRef.current.getContent();
    data.address = this.state.address;
    data.startupMaxDistance = parseInt(this.state.startupHeadquarterMaxDistance);
    data.startupStates = this.state.startupHeadquarterStates;

    if (this.state.organizationCanView === "Yes") {
      data.organizationCanView = true;
    } else {
      data.organizationCanView = false;
    }

    if (this.state.startupCanView === "Yes") {
      data.startupCanView = true;
    } else {
      data.startupCanView = false;
    }

    var tagIds = [];
    if (this.state.tagIds.length > 0) {
      this.state.tagIds.forEach((item) => {
        tagIds.push(item.id);
      });
    }
    data.tags = tagIds;

    var categoryIds = [];
    if (this.state.categoryIds.length > 0) {
      this.state.categoryIds.forEach((item) => {
        categoryIds.push(item.id);
      });
    }
    data.categories = categoryIds;

    var orgOrganizationTypeIds = [];
    if (this.state.orgOrganizationTypeIds.length > 0) {
      this.state.orgOrganizationTypeIds.forEach((item) => {
        orgOrganizationTypeIds.push(item.id);
      });
    }
    data.organizationTypes = orgOrganizationTypeIds;

    var orgVerticalIds = [];
    if (this.state.orgVerticalIds.length > 0) {
      this.state.orgVerticalIds.forEach((item) => {
        orgVerticalIds.push(item.id);
      });
    }
    data.organizationVerticals = orgVerticalIds;

    var orgOperatingStructureIds = [];
    if (this.state.orgOperatingStructureIds.length > 0) {
      this.state.orgOperatingStructureIds.forEach((item) => {
        orgOperatingStructureIds.push(item.id);
      });
    }
    data.organizationOperatingStructures = orgOperatingStructureIds;

    var orgTagIds = [];
    if (this.state.orgTagIds.length > 0) {
      this.state.orgTagIds.forEach((item) => {
        orgTagIds.push(item.id);
      });
    }
    data.organizationTags = orgTagIds;

    var orgRegionIds = [];
    if (this.state.orgRegionIds.length > 0) {
      this.state.orgRegionIds.forEach((item) => {
        orgRegionIds.push(item.id);
      });
    }
    data.organizationRegions = orgRegionIds;

    var orgOrganizationStatusIds = [];
    if (this.state.orgOrganizationStatusIds.length > 0) {
      this.state.orgOrganizationStatusIds.forEach((item) => {
        orgOrganizationStatusIds.push(item.id);
      });
    }
    data.organizationStatuses = orgOrganizationStatusIds;

    data.startupProgramStatus = this.state.startupProgramStatus;

    var startupTitleIds = [];
    if (this.state.startupTitleIds.length > 0) {
      this.state.startupTitleIds.forEach((item) => {
        startupTitleIds.push(item.id);
      });
    }
    data.startupTitles = startupTitleIds;

    var startupJobFunctionIds = [];
    if (this.state.startupJobFunctionIds.length > 0) {
      this.state.startupJobFunctionIds.forEach((item) => {
        startupJobFunctionIds.push(item.id);
      });
    }
    data.startupJobFunctions = startupJobFunctionIds;

    var startupIndustryTagIds = [];
    if (this.state.startupIndustryTagIds.length > 0) {
      this.state.startupIndustryTagIds.forEach((item) => {
        startupIndustryTagIds.push(item.id);
      });
    }
    data.startupIndustryTags = startupIndustryTagIds;

    var startupTechnologyTagIds = [];
    if (this.state.startupTechnologyTagIds.length > 0) {
      this.state.startupTechnologyTagIds.forEach((item) => {
        startupTechnologyTagIds.push(item.id);
      });
    }
    data.startupTechnologyTags = startupTechnologyTagIds;

    var startupHeadQuarterCountryIds = [];
    if (this.state.startupHeadQuarterCountryIds.length > 0) {
      this.state.startupHeadQuarterCountryIds.forEach((item) => {
        startupHeadQuarterCountryIds.push(item.id);
      });
    }
    data.startupCountries = startupHeadQuarterCountryIds;

    var startupHeadQuarterContinentIds = [];
    if (this.state.startupHeadQuarterContinentIds.length > 0) {
      this.state.startupHeadQuarterContinentIds.forEach((item) => {
        startupHeadQuarterContinentIds.push(item.id);
      });
    }
    data.startupContinents = startupHeadQuarterContinentIds;

    var startupFundingTypeIds = [];
    if (this.state.startupFundingTypeIds.length > 0) {
      this.state.startupFundingTypeIds.forEach((item) => {
        startupFundingTypeIds.push(item.id);
      });
    }
    data.startupFundingTypes = startupFundingTypeIds;

    data.startupMinAnnualRevenue = parseInt(this.state.startupMinAnnualRevenue);
    data.startupMaxAnnualRevenue = parseInt(this.state.startupMaxAnnualRevenue);

    data.startupMinTotalFundingAmountRaised = parseInt(this.state.startupMinTotalFundingAmountRaised);
    data.startupMaxTotalFundingAmountRaised = parseInt(this.state.startupMaxTotalFundingAmountRaised);

    data.startupMinLastFundingAmount = parseInt(this.state.startupMinLastFundingAmount);
    data.startupMaxLastFundingAmount = parseInt(this.state.startupMaxLastFundingAmount);

    data.startupMinSize = parseInt(this.state.startupMinSize);
    data.startupMaxSize = parseInt(this.state.startupMaxSize);

    if (this.state.startupLastRaisedStart !== null) {
      data.startupLastRaisedStart = moment(this.state.startupLastRaisedStart).format;
    }

    if (this.state.startupLastRaisedEnd !== null) {
      data.startupLastRaisedEnd = moment(this.state.startupLastRaisedEnd).format();
    }

    if (this.state.startupFoundedStart !== null) {
      data.startupFoundedStart = moment(this.state.startupFoundedStart).format();
    }

    if (this.state.startupFoundedEnd !== null) {
      data.startupFoundedEnd = moment(this.state.startupFoundedEnd).format();
    }

    this.setState({ loading: true });
    AuthToken();
    axios
      .put(`${process.env.GATSBY_API_URL}/global-networks/${this.props.globalNetworkId}`, data)
      .then(() => {
        this.props.enqueueSnackbar("Global network details updated", {
          variant: "success",
        });
        if (isBrowser()) {
          navigate("/app/global-networks");
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            this.props.enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          this.props.enqueueSnackbar("unable to connect to api", {
            variant: "error",
          });
        }
      });
  };

  render() {
    return (
      <Layout>
        <Paper elevation={1} sx={{ p: 2 }}>
          <TitleNav title="Edit Global Network" buttonLink="/app/global-networks" buttonTitle="Global Networks" />
          <Box sx={{ m: 2 }} />
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="name"
                  label="Name"
                  name="name"
                  value={this.state.name}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="keywords"
                  label="Keywords"
                  name="keywords"
                  value={this.state.keywords}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleUpdate}
                  type="email"
                />
              </Grid>
              <Grid item xs={3}>
                <AcceptingRequestSelect
                  required
                  acceptingRequest={this.state.acceptingRequests}
                  selectChanged={this.acceptingRequests}
                />
              </Grid>
              <Grid item xs={3}>
                <EnabledSelect required enabled={this.state.enabled} selectChanged={this.enabled} />
              </Grid>
              <Grid item xs={6}>
                <ConnectionWorkflowTierSelect
                  required
                  connectionWorkflowTier={this.state.connectionWorkflowTier}
                  selectChanged={this.connectionWorkflowTier}
                />
              </Grid>
              <Grid item xs={4}>
                <FeaturedSelect required featured={this.state.featured} selectChanged={this.featured} />
              </Grid>
              <Grid item xs={4}>
                <OrganizationCanViewSelect
                  canView={this.state.organizationCanView}
                  selectChanged={this.organizationCanView}
                />
              </Grid>
              <Grid item xs={4}>
                <StartupCanViewSelect
                  required
                  canView={this.state.startupCanView}
                  selectChanged={this.startupCanView}
                />
              </Grid>
              <Grid item xs={12}>
                <CategoriesAutocomplete
                  categoryType="Global Network"
                  ids={this.state.categoryIds}
                  selectChanged={this.handleCategorySelectUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <TagsAutocomplete
                  tagType="Global Network"
                  ids={this.state.tagIds}
                  selectChanged={this.handleTagSelectUpdate}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  variant="outlined"
                  fullWidth
                  multiline
                  minRows={5}
                  id="address"
                  label="Address"
                  name="address"
                  value={this.state.address}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={4}>
                <Stack direction="row" spacing={2}>
                  <Card sx={{ maxWidth: 150 }}>
                    <CardMedia component="img" height="150" image={this.state.image} />
                  </Card>
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="contained-button-file"
                    type="file"
                    onChange={this.selectFile}
                  />
                  <label htmlFor="contained-button-file">
                    <LoadingButton
                      fullWidth
                      loadingPosition="start"
                      startIcon={<FileUploadIcon />}
                      loading={this.state.uploading}
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      Upload Image
                    </LoadingButton>
                  </label>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  multiline
                  minRows={5}
                  id="shortDescription"
                  label="Short Description"
                  name="shortDescription"
                  value={this.state.shortDescription}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Long Description</Typography>
                <Editor
                  apiKey={`${process.env.GATSBY_TINY_MCE_API_KEY}`}
                  initialValue={this.state.longDescription}
                  onInit={(evt, editor) => (this.editorRef.current = editor)}
                  init={{
                    height: 500,
                    menubar: false,
                    plugins: "lists link paste code",
                    toolbar:
                      "undo redo | bold italic | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | link | code",
                  }}
                />
                <Typography variant="caption">
                  Any fonts or colors set here will overide the fonts and colors in the member site
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider>Organization Filters</Divider>
              </Grid>
              <Grid item xs={12}>
                <OrganizationTypesAutocomplete
                  ids={this.state.orgOrganizationTypeIds}
                  selectChanged={this.handleOrgOrganizationTypesUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <VerticalsAutocomplete
                  ids={this.state.orgVerticalIds}
                  selectChanged={this.handleOrgVerticalsSelectUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <OperatingStructuresAutocomplete
                  ids={this.state.orgOperatingStructureIds}
                  selectChanged={this.handleOrgOperatingStructuresSelectUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <TagsAutocomplete
                  tagType="Global Network"
                  ids={this.state.orgTagIds}
                  selectChanged={this.handleOrgTagsSelectUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <RegionsAutocomplete ids={this.state.orgRegionIds} selectChanged={this.handleOrgRegionsSelectUpdate} />
              </Grid>
              <Grid item xs={12}>
                <OrganizationStatusesAutocomplete
                  ids={this.state.orgOrganizationStatusIds}
                  selectChanged={this.handleOrgOrganizationStatusesSelectUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider>Startup Filters</Divider>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="startupHeadquarterMaxDistance"
                  label="Startup Headquarter Max Distance in Miles"
                  name="startupHeadquarterMaxDistance"
                  value={this.state.startupHeadquarterMaxDistance}
                  onChange={this.handleUpdate}
                  type="number"
                />
              </Grid>
              <Grid item xs={9}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="startupHeadquarterStates"
                  label="Startup Headquarter States"
                  name="startupHeadquarterStates"
                  value={this.state.startupHeadquarterStates}
                  onChange={this.handleUpdate}
                />
                <Typography variant="caption">Comma separated list of states.</Typography>
              </Grid>
              <Grid item xs={12}>
                <ProgramStatsusSelect
                  status={this.state.startupProgramStatus}
                  selectChanged={this.handleStartupProgramStatusSelectUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <TitlesAutocomplete
                  ids={this.state.startupTitleIds}
                  selectChanged={this.handleStartupTitleSelectUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <JobFunctionsAutocomplete
                  ids={this.state.startupJobFunctionIds}
                  selectChanged={this.handleStartupJobFunctionSelectUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <IndustryTagsAutocomplete
                  ids={this.state.startupIndustryTagIds}
                  selectChanged={this.handleStartupIndustryTagSelectUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <TechnologyTagsAutocomplete
                  ids={this.state.startupTechnologyTagIds}
                  selectChanged={this.handleStartupTechnologyTagSelectUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <CountriesAutocomplete
                  ids={this.state.startupHeadQuarterCountryIds}
                  selectChanged={this.handleStartupCountrySelectUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <ContinentsAutocomplete
                  ids={this.state.startupHeadQuarterContinentIds}
                  selectChanged={this.handleStartupContinentSelectUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <FundingTypesAutcomplete
                  ids={this.state.startupFundingTypeIds}
                  selectChanged={this.handleStartupFundingTypeSelectUpdate}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="startupMinAnnualRevenue"
                  label="Minimum Annual Revenue"
                  name="startupMinAnnualRevenue"
                  value={this.state.startupMinAnnualRevenue}
                  onChange={this.handleUpdate}
                  type="number"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="startupMaxAnnualRevenue"
                  label="Maximum Annual Revenue"
                  name="startupMaxAnnualRevenue"
                  value={this.state.startupMaxAnnualRevenue}
                  onChange={this.handleUpdate}
                  type="number"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="startupMinTotalFundingAmountRaised"
                  label="Minimum Total Funding Amount Raised"
                  name="startupMinTotalFundingAmountRaised"
                  value={this.state.startupMinTotalFundingAmountRaised}
                  onChange={this.handleUpdate}
                  type="number"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="startupMaxTotalFundingAmountRaised"
                  label="Maximum Total Funding Amount Raised"
                  name="startupMaxTotalFundingAmountRaised"
                  value={this.state.startupMaxTotalFundingAmountRaised}
                  onChange={this.handleUpdate}
                  type="number"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="startupMinLastFundingAmount"
                  label="Minimum Last Funding Amount Raised"
                  name="startupMinLastFundingAmount"
                  value={this.state.startupMinLastFundingAmount}
                  onChange={this.handleUpdate}
                  type="number"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="startupMaxLastFundingAmount"
                  label="Maximum Last Funding Amount Raised"
                  name="startupMaxLastFundingAmount"
                  value={this.state.startupMaxLastFundingAmount}
                  onChange={this.handleUpdate}
                  type="number"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="startupMinSize"
                  label="Minimum Team Size"
                  name="startupMinSize"
                  value={this.state.startupMinSize}
                  onChange={this.handleUpdate}
                  type="number"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="startupMaxSize"
                  label="Maximum Team Size"
                  name="startupMaxSize"
                  value={this.state.startupMaxSize}
                  onChange={this.handleUpdate}
                  type="number"
                />
              </Grid>
              <Grid item xs={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Last Raised Start Date"
                    minDate={new Date("1900-01-01")}
                    maxDate={new Date()}
                    value={this.state.startupLastRaisedStart}
                    onChange={(newValue) => {
                      this.setState({
                        startupLastRaisedStart: newValue,
                      });
                    }}
                    renderInput={(params) => <TextField {...params} helperText={null} />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Last Raised End Date"
                    minDate={new Date("1900-01-01")}
                    maxDate={new Date()}
                    value={this.state.startupLastRaisedEnd}
                    onChange={(newValue) => {
                      this.setState({
                        startupLastRaisedEnd: newValue,
                      });
                    }}
                    renderInput={(params) => <TextField {...params} helperText={null} />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Founded Start Date"
                    minDate={new Date("1900-01-01")}
                    maxDate={new Date()}
                    value={this.state.startupFoundedStart}
                    onChange={(newValue) => {
                      this.setState({
                        startupFoundedStart: newValue,
                      });
                    }}
                    renderInput={(params) => <TextField {...params} helperText={null} />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Founded End Date"
                    minDate={new Date("1900-01-01")}
                    maxDate={new Date()}
                    value={this.state.startupFoundedEnd}
                    onChange={(newValue) => {
                      this.setState({
                        startupFoundedEnd: newValue,
                      });
                    }}
                    renderInput={(params) => <TextField {...params} helperText={null} />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid container item xs={12} justifyContent="right">
                <LoadingButton
                  fullWidth
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  loading={this.state.loading}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Layout>
    );
  }
}

export default withSnackbar(EditGlobalNetwork);
