import * as React from "react";
import axios from "axios";
import AuthToken from "../../helpers/AuthToken";
import { withSnackbar } from "notistack";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import Layout from "../Layout";
import TitleNav from "../TitleNav";
import FileUpload from "../widgets/FileUpload";

import OrganizationTypeSelect from "../widgets/OrganizationTypeSelect";

import { navigate } from "gatsby";
import { isBrowser, signOut } from "../../helpers/LocalStorage";

class AddContentBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      organizationTypeId: ``,
      title: ``,
      body: ``,
      file: ``,
      buttonLabel: ``,
      buttonLink: ``,
      loading: false,
    };
  }

  handleUpdate = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  organizationTypeUpdate = (value) => {
    this.setState({
      organizationTypeId: value,
    });
  };

  selectFile = (files) => {
    this.setState({
      file: files[0],
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("organizationTypeID", this.state.organizationTypeId);
    formData.append("title", this.state.title);
    formData.append("body", this.state.body);
    formData.append("buttonLabel", this.state.buttonLabel);
    formData.append("buttonLink", this.state.buttonLink);

    formData.append("file", this.state.file);

    this.setState({ loading: true });
    AuthToken();
    axios
      .post(`${process.env.GATSBY_API_URL}/content-blocks`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        this.props.enqueueSnackbar("Content block added", {
          variant: "success",
        });
        if (isBrowser()) {
          navigate("/app/content-blocks");
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            this.props.enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          this.props.enqueueSnackbar("Unable to connect to the API", { variant: "error" });
        }
      });
  };

  render() {
    return (
      <Layout>
        <Paper elevation={1} sx={{ p: 2 }}>
          <TitleNav title="Add Content Block" buttonLink="/app/content-blocks" buttonTitle="Content Blocks" />
          <Box sx={{ m: 2 }} />
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="title"
                  label="Title"
                  name="title"
                  value={this.state.title}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={6}>
                <OrganizationTypeSelect
                  required
                  organizationType={this.state.organizationTypeId}
                  selectChanged={this.organizationTypeUpdate}
                />
              </Grid>
              <Grid item xs={6}>
                <FileUpload selectFile={this.selectFile} buttonTitle="Select Image" />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  multiline
                  rows={4}
                  id="body"
                  label="Body"
                  name="body"
                  value={this.state.body}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="buttonLabel"
                  label="Button Label"
                  name="buttonLabel"
                  value={this.state.buttonLabel}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="buttonLink"
                  label="Button Link"
                  name="buttonLink"
                  value={this.state.buttonLink}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid container item xs={12} justifyContent="right">
                <LoadingButton
                  fullWidth
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  loading={this.state.loading}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Layout>
    );
  }
}

export default withSnackbar(AddContentBlock);
