import * as React from "react";
import axios from "axios";
import AuthToken from "../../helpers/AuthToken";
import { withSnackbar } from "notistack";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Layout from "../Layout";
import TitleNav from "../TitleNav";
import OrganizationTypeSelect from "../widgets/OrganizationTypeSelect";

import { navigate } from "gatsby";
import { isBrowser, signOut } from "../../helpers/LocalStorage";

class EditContentBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      organizationTypeId: ``,
      title: ``,
      body: ``,
      image: ``,
      buttonLabel: ``,
      buttonLink: ``,
      loading: false,
      uploading: false,
    };
  }

  componentDidMount() {
    AuthToken();
    axios
      .get(`${process.env.GATSBY_API_URL}/content-blocks/${this.props.contentBlockId}`)
      .then((response) => {
        const contentBlock = response.data;
        this.setState({
          organizationTypeId: contentBlock.organizationType.id,
          title: contentBlock.title,
          body: contentBlock.body,
          image: contentBlock.image,
          buttonLabel: contentBlock.buttonLabel,
          buttonLink: contentBlock.buttonLink,
        });
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            this.props.enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          this.props.enqueueSnackbar("Unable to connect to the API", { variant: "error" });
        }
      });
  }

  handleUpdate = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  organizationTypeUpdate = (value) => {
    this.setState({
      organizationTypeId: value,
    });
  };

  selectFile = (event) => {
    const images = event.target.files;
    if (images.length !== 1) {
      this.props.enqueueSnackbar("only 1 image can be uploaded", { variant: "error" });
      return;
    }

    const formData = new FormData();
    formData.append("file", images[0]);
    this.setState({ uploading: true });

    AuthToken();
    axios
      .post(`${process.env.GATSBY_API_URL}/content-blocks/${this.props.contentBlockId}/image`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        this.setState({ uploading: false, image: response.data });
        this.props.enqueueSnackbar("Image updated", { variant: "success" });
      })
      .catch((err) => {
        this.setState({ uploading: false });
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            this.props.enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          this.props.enqueueSnackbar("Unable to connect to the API", { variant: "error" });
        }
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      organizationTypeID: this.state.organizationTypeId,
      title: this.state.title,
      body: this.state.body,
      buttonLabel: this.state.buttonLabel,
      buttonLink: this.state.buttonLink,
    };

    this.setState({ loading: true });
    AuthToken();
    axios
      .put(`${process.env.GATSBY_API_URL}/content-blocks/${this.props.contentBlockId}`, data)
      .then(() => {
        this.props.enqueueSnackbar("Content block updated", {
          variant: "success",
        });
        if (isBrowser()) {
          navigate("/app/content-blocks");
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            this.props.enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          this.props.enqueueSnackbar("Unable to connect to the API", { variant: "error" });
        }
      });
  };

  render() {
    return (
      <Layout>
        <Paper elevation={1} sx={{ p: 2 }}>
          <TitleNav title="Edit Content Block" buttonLink="/app/content-blocks" buttonTitle="Content Blocks" />
          <Box sx={{ m: 2 }} />
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="title"
                  label="Title"
                  name="title"
                  value={this.state.title}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <OrganizationTypeSelect
                  required
                  organizationTypeId={this.state.organizationTypeId}
                  selectChanged={this.organizationTypeUpdate}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  multiline
                  minRows={6}
                  id="body"
                  label="Body"
                  name="body"
                  value={this.state.body}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={4}>
                <Stack direction="row" spacing={2}>
                  <Card sx={{ maxWidth: 150 }}>
                    <CardMedia component="img" height="150" image={this.state.image} />
                  </Card>
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="contained-button-file"
                    type="file"
                    onChange={this.selectFile}
                  />
                  <label htmlFor="contained-button-file">
                    <LoadingButton
                      fullWidth
                      loadingPosition="start"
                      startIcon={<FileUploadIcon />}
                      loading={this.state.uploading}
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      Upload Image
                    </LoadingButton>
                  </label>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="buttonLabel"
                  label="Button Label"
                  name="buttonLabel"
                  value={this.state.buttonLabel}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="buttonLink"
                  label="Button Link"
                  name="buttonLink"
                  value={this.state.buttonLink}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid container item xs={12} justifyContent="right">
                <LoadingButton
                  fullWidth
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  loading={this.state.loading}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Layout>
    );
  }
}

export default withSnackbar(EditContentBlock);
