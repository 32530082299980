import React from "react";
import { Router } from "@reach/router";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { SnackbarProvider } from "notistack";

import PrivateRoute from "../components/app/PrivateRoute";

import Activate from "../components/app/Activate";

import ContentIntroductions from "../components/app/ContentIntroductions";
import EditContentIntroduction from "../components/app/EditContentIntroduction";

import ContentBlocks from "../components/app/ContentBlocks";
import AddContentBlock from "../components/app/AddContentBlock";
import EditContentBlock from "../components/app/EditContentBlock";

import Categories from "../components/app/lookup-tables/Categories";
import AddCategory from "../components/app/lookup-tables/AddCategory";
import EditCategory from "../components/app/lookup-tables/EditCategory";

import Continents from "../components/app/lookup-tables/Continents";
import AddContinent from "../components/app/lookup-tables/AddContinent";
import EditContinent from "../components/app/lookup-tables/EditContinent";

import Currencies from "../components/app/lookup-tables/Currencies";
import AddCurrency from "../components/app/lookup-tables/AddCurrency";
import EditCurrency from "../components/app/lookup-tables/EditCurrency";

import DataReports from "../components/app/DataReports";
import AddDataReport from "../components/app/AddDataReport";
import EditDataReport from "../components/app/EditDataReport";

import DocumentTypes from "../components/app/lookup-tables/DocumentTypes";
import AddDocumentType from "../components/app/lookup-tables/AddDocumentType";
import EditDocumentType from "../components/app/lookup-tables/EditDocumentType";
import DataReportAttachments from "../components/app/DataReportAttachments";

import Events from "../components/app/Events";
import AddEvent from "../components/app/AddEvent";
import EditEvent from "../components/app/EditEvent";

import FeaturedNews from "../components/app/FeaturedNews";
import AddFeaturedNews from "../components/app/AddFeaturedNews";
import EditFeaturedNews from "../components/app/EditFeaturedNews";

import FounderWellness from "../components/app/FounderWellness";
import AddFounderWellness from "../components/app/AddFounderWellness";
import EditFounderWellness from "../components/app/EditFounderWellness";
import FounderWellnessAttachments from "../components/app/FounderWellnessAttachments";

import FundingTypes from "../components/app/lookup-tables/FundingTypes";
import AddFundingType from "../components/app/lookup-tables/AddFundingType";
import EditFundingType from "../components/app/lookup-tables/EditFundingType";

import GanVentures from "../components/app/GanVentures";
import AddGanVenture from "../components/app/AddGanVenture";
import EditGanVenture from "../components/app/EditGanVenture";
import GanVentureAttachments from "../components/app/GanVentureAttachments";

import GlobalNetworks from "../components/app/GlobalNetworks";
import AddGlobalNetwork from "../components/app/AddGlobalNetwork";
import EditGlobalNetwork from "../components/app/EditGlobalNetwork";
import GlobalNetworkQuestions from "../components/app/GlobalNetworkQuestions";
import AddGlobalNetworkQuestion from "../components/app/AddGlobalNetworkQuestion";
import EditGlobalNetworkQuestion from "../components/app/EditGlobalNetworkQuestion";

import IndustryTags from "../components/app/lookup-tables/IndustryTags";
import AddIndustryTag from "../components/app/lookup-tables/AddIndustryTag";
import EditIndustryTag from "../components/app/lookup-tables/EditIndustryTag";

import JobFunctions from "../components/app/lookup-tables/JobFunctions";
import AddJobFunction from "../components/app/lookup-tables/AddJobFunction";
import EditJobFunction from "../components/app/lookup-tables/EditJobFunction";

import OperatingStructures from "../components/app/lookup-tables/OperatingStructures";
import AddOperatingStructure from "../components/app/lookup-tables/AddOperatingStructure";
import EditOperatingStructure from "../components/app/lookup-tables/EditOperatingStructure";

import Organizations from "../components/app/Organizations";
import AddOrganization from "../components/app/AddOrganization";
import EditOrganization from "../components/app/EditOrganization";

import OrganizationStatuses from "../components/app/lookup-tables/OrganizationStatuses";
import AddOrganizationStatus from "../components/app/lookup-tables/AddOrganizationStatus";
import EditOrganizationStatus from "../components/app/lookup-tables/EditOrganizationStatus";

import OrganizationTypes from "../components/app/lookup-tables/OrganizationTypes";
import AddOrganizationType from "../components/app/lookup-tables/AddOrganizationType";
import EditOrganizationType from "../components/app/lookup-tables/EditOrganizationType";

import Regions from "../components/app/lookup-tables/Regions";
import AddRegion from "../components/app/lookup-tables/AddRegion";
import EditRegion from "../components/app/lookup-tables/EditRegion";

import Tags from "../components/app/lookup-tables/Tags";
import AddTag from "../components/app/lookup-tables/AddTag";
import EditTag from "../components/app/lookup-tables/EditTag";

import TechnologyTags from "../components/app/lookup-tables/TechnologyTags";
import AddTechnologyTag from "../components/app/lookup-tables/AddTechnologyTag";
import EditTechnologyTag from "../components/app/lookup-tables/EditTechnologyTag";

import Titles from "../components/app/lookup-tables/Titles";
import AddTitle from "../components/app/lookup-tables/AddTitle";
import EditTitle from "../components/app/lookup-tables/EditTitle";

import Verticals from "../components/app/lookup-tables/Verticals";
import AddVertical from "../components/app/lookup-tables/AddVertical";
import EditVertical from "../components/app/lookup-tables/EditVertical";

import Dashboard from "../components/app/Dashboard";

import Login from "../components/app/Login";
import Password from "../components/app/Password";
import Profile from "../components/app/Profile";
import ResetPassword from "../components/app/ResetPassword";

import Members from "../components/app/Members";
import EditMember from "../components/app/EditMember";

import MemberInvites from "../components/app/MemberInvites";
import InviteMember from "../components/app/InviteMember";

import Resources from "../components/app/Resources";
import AddAttachmentResource from "../components/app/AddAttachmentResource";
import EditAttachmentResource from "../components/app/EditAttachmentResource";
import AddEmbeddedResource from "../components/app/AddEmbeddedResource";
import EditEmbeddedResource from "../components/app/EditEmbeddedResource";

import PastMeetups from "../components/app/PastMeetups";
import AddPastMeetup from "../components/app/AddPastMeetup";
import EditPastMeetup from "../components/app/EditPastMeetup";
import PastMeetupAttachments from "../components/app/PastMeetupAttachments";

import Perks from "../components/app/Perks";
import AddPerk from "../components/app/AddPerk";
import EditPerk from "../components/app/EditPerk";
import RedeemedPerks from "../components/app/RedeemedPerks";

import OrganizationPerks from "../components/app/OrganizationPerks";
import RedeemedOrganizationPerks from "../components/app/RedeemedOrganizationPerks";

import Playbooks from "../components/app/Playbooks";
import AddPlaybook from "../components/app/AddPlaybook";
import EditPlaybook from "../components/app/EditPlaybook";
import PlaybookSections from "../components/app/PlaybookSections";
import AddPlaybookSection from "../components/app/AddPlaybookSection";
import EditPlaybookSection from "../components/app/EditPlaybookSection";
import PlaybookSectionAttachments from "../components/app/PlaybookSectionAttachments";

import Settings from "../components/app/Settings";
import AddSetting from "../components/app/AddSetting";

import StartupContents from "../components/app/StartupContents";
import AddStartupContent from "../components/app/AddStartupContent";
import EditStartupContent from "../components/app/EditStartupContent";

import Templates from "../components/app/Templates";
import AddTemplate from "../components/app/AddTemplate";
import EditTemplate from "../components/app/EditTemplate";

import WhitePapers from "../components/app/WhitePapers";
import AddWhitePaper from "../components/app/AddWhitePaper";
import EditWhitePaper from "../components/app/EditWhitePaper";

import Users from "../components/app/Users";
import InviteUser from "../components/app/InviteUser";
import EditUser from "../components/app/EditUser";

export default function Home() {
  const notistackRef = React.createRef();
  const onClickDismissSnackbar = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      ref={notistackRef}
      action={(key) => (
        <IconButton aria-label="close" onClick={onClickDismissSnackbar(key)}>
          <CloseIcon />
        </IconButton>
      )}
    >
      <Router>
        <PrivateRoute path="/app" component={Dashboard} />

        <PrivateRoute path="/app/content-introductions" component={ContentIntroductions} />
        <PrivateRoute path="/app/content-introductions/:contentIntroductionId" component={EditContentIntroduction} />

        <PrivateRoute path="/app/content-blocks" component={ContentBlocks} />
        <PrivateRoute path="/app/content-blocks/add" component={AddContentBlock} />
        <PrivateRoute path="/app/content-blocks/:contentBlockId" component={EditContentBlock} />

        <PrivateRoute path="/app/categories" component={Categories} />
        <PrivateRoute path="/app/categories/add" component={AddCategory} />
        <PrivateRoute path="/app/categories/:categoryId" component={EditCategory} />

        <PrivateRoute path="/app/continents" component={Continents} />
        <PrivateRoute path="/app/continents/add" component={AddContinent} />
        <PrivateRoute path="/app/continents/:continentId" component={EditContinent} />

        <PrivateRoute path="/app/currencies" component={Currencies} />
        <PrivateRoute path="/app/currencies/add" component={AddCurrency} />
        <PrivateRoute path="/app/currencies/:currencyId" component={EditCurrency} />

        <PrivateRoute path="/app/data-reports" component={DataReports} />
        <PrivateRoute path="/app/data-reports/add" component={AddDataReport} />
        <PrivateRoute path="/app/data-reports/:dataReportId" component={EditDataReport} />
        <PrivateRoute path="/app/data-reports/:dataReportId/attachments" component={DataReportAttachments} />

        <PrivateRoute path="/app/document-types" component={DocumentTypes} />
        <PrivateRoute path="/app/document-types/add" component={AddDocumentType} />
        <PrivateRoute path="/app/document-types/:documentTypeId" component={EditDocumentType} />

        <PrivateRoute path="/app/events" component={Events} />
        <PrivateRoute path="/app/events/add" component={AddEvent} />
        <PrivateRoute path="/app/events/:eventId" component={EditEvent} />

        <PrivateRoute path="/app/featured-news" component={FeaturedNews} />
        <PrivateRoute path="/app/featured-news/add" component={AddFeaturedNews} />
        <PrivateRoute path="/app/featured-news/:featuredNewsItemId" component={EditFeaturedNews} />

        <PrivateRoute path="/app/founder-wellness" component={FounderWellness} />
        <PrivateRoute path="/app/founder-wellness/add" component={AddFounderWellness} />
        <PrivateRoute path="/app/founder-wellness/:founderWellnessId" component={EditFounderWellness} />
        <PrivateRoute
          path="/app/founder-wellness/:founderWellnessId/attachments"
          component={FounderWellnessAttachments}
        />

        <PrivateRoute path="/app/funding-types" component={FundingTypes} />
        <PrivateRoute path="/app/funding-types/add" component={AddFundingType} />
        <PrivateRoute path="/app/funding-types/:fundingTypeId" component={EditFundingType} />

        <PrivateRoute path="/app/gan-ventures" component={GanVentures} />
        <PrivateRoute path="/app/gan-ventures/add" component={AddGanVenture} />
        <PrivateRoute path="/app/gan-ventures/:ganVentureId" component={EditGanVenture} />
        <PrivateRoute path="/app/gan-ventures/:ganVentureId/attachments" component={GanVentureAttachments} />

        <PrivateRoute path="/app/global-networks" component={GlobalNetworks} />
        <PrivateRoute path="/app/global-networks/add" component={AddGlobalNetwork} />
        <PrivateRoute path="/app/global-networks/:globalNetworkId" component={EditGlobalNetwork} />
        <PrivateRoute path="/app/global-networks/:globalNetworkId/questions" component={GlobalNetworkQuestions} />
        <PrivateRoute path="/app/global-networks/:globalNetworkId/questions/add" component={AddGlobalNetworkQuestion} />
        <PrivateRoute
          path="/app/global-networks/:globalNetworkId/questions/:questionId"
          component={EditGlobalNetworkQuestion}
        />

        <PrivateRoute path="/app/industry-tags" component={IndustryTags} />
        <PrivateRoute path="/app/industry-tags/add" component={AddIndustryTag} />
        <PrivateRoute path="/app/industry-tags/:industryTagId" component={EditIndustryTag} />

        <PrivateRoute path="/app/job-functions" component={JobFunctions} />
        <PrivateRoute path="/app/job-functions/add" component={AddJobFunction} />
        <PrivateRoute path="/app/job-functions/:jobFunctionId" component={EditJobFunction} />

        <PrivateRoute path="/app/members/invites/send" component={InviteMember} />

        <PrivateRoute path="/app/members" component={Members} />
        <PrivateRoute path="/app/members/invites" component={MemberInvites} />
        <PrivateRoute path="/app/members/:memberId" component={EditMember} />

        <PrivateRoute path="/app/resources" component={Resources} />
        <PrivateRoute path="/app/resources/attachment/add" component={AddAttachmentResource} />
        <PrivateRoute path="/app/resources/attachment/:resourceId" component={EditAttachmentResource} />
        <PrivateRoute path="/app/resources/embedded/add" component={AddEmbeddedResource} />
        <PrivateRoute path="/app/resources/embedded/:resourceId" component={EditEmbeddedResource} />

        <PrivateRoute path="/app/operating-structures" component={OperatingStructures} />
        <PrivateRoute path="/app/operating-structures/add" component={AddOperatingStructure} />
        <PrivateRoute path="/app/operating-structures/:operatingStructureId" component={EditOperatingStructure} />

        <PrivateRoute path="/app/organizations" component={Organizations} />
        <PrivateRoute path="/app/organizations/add" component={AddOrganization} />
        <PrivateRoute path="/app/organizations/perks" component={OrganizationPerks} />
        <PrivateRoute path="/app/organizations/perks/redeemed" component={RedeemedOrganizationPerks} />
        <PrivateRoute path="/app/organizations/:organizationId" component={EditOrganization} />

        <PrivateRoute path="/app/organization-statuses" component={OrganizationStatuses} />
        <PrivateRoute path="/app/organization-statuses/add" component={AddOrganizationStatus} />
        <PrivateRoute path="/app/organization-statuses/:organizationStatusId" component={EditOrganizationStatus} />

        <PrivateRoute path="/app/organization-types" component={OrganizationTypes} />
        <PrivateRoute path="/app/organization-types/add" component={AddOrganizationType} />
        <PrivateRoute path="/app/organization-types/:organizationTypeId" component={EditOrganizationType} />

        <PrivateRoute path="/app/perks" component={Perks} />
        <PrivateRoute path="/app/perks/add" component={AddPerk} />
        <PrivateRoute path="/app/perks/redeemed" component={RedeemedPerks} />
        <PrivateRoute path="/app/perks/:perkId" component={EditPerk} />

        <PrivateRoute path="/app/past-meetups" component={PastMeetups} />
        <PrivateRoute path="/app/past-meetups/add" component={AddPastMeetup} />
        <PrivateRoute path="/app/past-meetups/:pastMeetupId" component={EditPastMeetup} />
        <PrivateRoute path="/app/past-meetups/:pastMeetupId/attachments" component={PastMeetupAttachments} />

        <PrivateRoute path="/app/playbooks" component={Playbooks} />
        <PrivateRoute path="/app/playbooks/add" component={AddPlaybook} />
        <PrivateRoute path="/app/playbooks/:playbookId" component={EditPlaybook} />
        <PrivateRoute path="/app/playbooks/:playbookId/sections" component={PlaybookSections} />
        <PrivateRoute path="/app/playbooks/:playbookId/sections/add" component={AddPlaybookSection} />
        <PrivateRoute path="/app/playbooks/:playbookId/sections/:playbookSectionId" component={EditPlaybookSection} />
        <PrivateRoute
          path="/app/playbooks/:playbookId/sections/:playbookSectionId/attachments"
          component={PlaybookSectionAttachments}
        />

        <PrivateRoute path="/app/settings" component={Settings} />
        <PrivateRoute path="/app/settings/add" component={AddSetting} />

        <PrivateRoute path="/app/regions" component={Regions} />
        <PrivateRoute path="/app/regions/add" component={AddRegion} />
        <PrivateRoute path="/app/regions/:regionId" component={EditRegion} />

        <PrivateRoute path="/app/startup-contents" component={StartupContents} />
        <PrivateRoute path="/app/startup-contents/add" component={AddStartupContent} />
        <PrivateRoute path="/app/startup-contents/:startupContentId" component={EditStartupContent} />

        <PrivateRoute path="/app/tags" component={Tags} />
        <PrivateRoute path="/app/tags/add" component={AddTag} />
        <PrivateRoute path="/app/tags/:tagId" component={EditTag} />

        <PrivateRoute path="/app/technology-tags" component={TechnologyTags} />
        <PrivateRoute path="/app/technology-tags/add" component={AddTechnologyTag} />
        <PrivateRoute path="/app/technology-tags/:technologyTagId" component={EditTechnologyTag} />

        <PrivateRoute path="/app/titles" component={Titles} />
        <PrivateRoute path="/app/titles/add" component={AddTitle} />
        <PrivateRoute path="/app/titles/:titleId" component={EditTitle} />

        <PrivateRoute path="/app/templates" component={Templates} />
        <PrivateRoute path="/app/templates/add" component={AddTemplate} />
        <PrivateRoute path="/app/templates/:templateId" component={EditTemplate} />

        <PrivateRoute path="/app/users" component={Users} />
        <PrivateRoute path="/app/users/invite" component={InviteUser} />
        <PrivateRoute path="/app/users/:userId" component={EditUser} />
        <PrivateRoute path="/app/password" component={Password} />
        <PrivateRoute path="/app/profile" component={Profile} />
        <PrivateRoute path="/app/verticals" component={Verticals} />
        <PrivateRoute path="/app/verticals/add" component={AddVertical} />
        <PrivateRoute path="/app/verticals/:verticalId" component={EditVertical} />

        <PrivateRoute path="/app/white-papers" component={WhitePapers} />
        <PrivateRoute path="/app/white-papers/add" component={AddWhitePaper} />
        <PrivateRoute path="/app/white-papers/:whitePaperId" component={EditWhitePaper} />

        <Activate path="/app/activate/:token" />
        <ResetPassword path="/app/reset/:token" />
        <Login path="/app/signin" />
      </Router>
    </SnackbarProvider>
  );
}
