import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import SendTimeExtension from "@mui/icons-material/SendTimeExtension";
import AddIcon from "@mui/icons-material/Add";
import { withSnackbar, useSnackbar } from "notistack";
import axios from "axios";

import AuthToken from "../helpers/AuthToken";
import { signOut } from "../helpers/LocalStorage";

const TitleAndResendAllNav = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);

  function resendAllInvites() {
    if (window.confirm("Are you sure you want to resend all invites?")) {
      setLoading(true);
      AuthToken();
      axios
        .put(`${process.env.GATSBY_API_URL}/members/invites/resendall`, {})
        .then(() => {
          setLoading(false);
          enqueueSnackbar("All invites queued for resending", { variant: "success" });
        })
        .catch((err) => {
          setLoading(false);
          if (err.response) {
            if (err.response.status === 401 || err.response.status === 403) {
              signOut();
            } else {
              enqueueSnackbar(err.response.data.reason, {
                variant: "error",
              });
            }
          } else {
            this.props.enqueueSnackbar("Unable to connect to the API", { variant: "error" });
          }
        });
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Typography component="h1" variant="h4">
          {props.title}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography component="h1" variant="h4" align="right">
          <Stack direction="row" spacing={2} justifyContent="right">
            <LoadingButton
              variant="outlined"
              startIcon={<SendTimeExtension />}
              loadingPosition="start"
              loading={loading}
              onClick={resendAllInvites}
            >
              Resend All
            </LoadingButton>
            <Button variant="outlined" startIcon={<AddIcon />} href={props.buttonLink}>
              {props.buttonTitle}
            </Button>
          </Stack>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default withSnackbar(TitleAndResendAllNav);
