import * as React from "react";
import axios from "axios";
import AuthToken from "../../helpers/AuthToken";
import { withSnackbar } from "notistack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { Editor } from "@tinymce/tinymce-react";
import Layout from "../Layout";
import TitleNav from "../TitleNav";
import OrganizationTypeSelect from "../widgets/OrganizationTypeSelect";

import { navigate } from "gatsby";
import { isBrowser, signOut } from "../../helpers/LocalStorage";

class EditFeaturedNews extends React.Component {
  constructor(props) {
    super(props);
    this.editorRef = React.createRef();
    this.state = {
      organizationTypeId: ``,
      title: ``,
      body: ``,
      loading: false,
    };
  }

  componentDidMount() {
    AuthToken();
    axios
      .get(`${process.env.GATSBY_API_URL}/featured-news/${this.props.featuredNewsItemId}`)
      .then((response) => {
        const featuredNewsItem = response.data;
        this.setState({
          organizationTypeId: featuredNewsItem.organizationType.id,
          title: featuredNewsItem.title,
          body: featuredNewsItem.body,
        });
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else if (err.response.status === 404) {
            if (isBrowser()) {
              navigate("/app/media");
            }
          } else {
            this.props.enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          this.props.enqueueSnackbar("Unable to connect to the API", { variant: "error" });
        }
      });
  }

  handleUpdate = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  organizationTypeUpdate = (value) => {
    this.setState({
      organizationTypeId: value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      organizationTypeID: this.state.organizationTypeId,
      title: this.state.title,
      body: this.editorRef.current.getContent(),
    };
    this.setState({ loading: true });
    AuthToken();
    axios
      .put(`${process.env.GATSBY_API_URL}/featured-news/${this.props.featuredNewsItemId}`, data)
      .then(() => {
        this.props.enqueueSnackbar("Featured news item updated", {
          variant: "success",
        });
        if (isBrowser()) {
          navigate("/app/featured-news");
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            this.props.enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          this.props.enqueueSnackbar("Unable to connect to the API", { variant: "error" });
        }
      });
  };

  render() {
    return (
      <Layout>
        <Paper elevation={1} sx={{ p: 2 }}>
          <TitleNav title="Edit Featured News Item" buttonLink="/app/featured-news" buttonTitle="Featured News" />
          <Box sx={{ m: 2 }} />
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="title"
                  label="Title"
                  name="title"
                  value={this.state.title}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={6}>
                <OrganizationTypeSelect
                  required
                  organizationTypeId={this.state.organizationTypeId}
                  selectChanged={this.organizationTypeUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <Editor
                  apiKey={`${process.env.GATSBY_TINY_MCE_API_KEY}`}
                  initialValue={this.state.body}
                  onInit={(evt, editor) => (this.editorRef.current = editor)}
                  init={{
                    height: 500,
                    menubar: false,
                    plugins: "link",
                    toolbar: "undo redo | bold italic | link",
                  }}
                />
                <Typography variant="caption">
                  Any fonts or colors set here will overide the fonts and colors in the member site
                </Typography>
              </Grid>
              <Grid container item xs={12} justifyContent="right">
                <LoadingButton
                  fullWidth
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  loading={this.state.loading}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Layout>
    );
  }
}

export default withSnackbar(EditFeaturedNews);
