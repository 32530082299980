import * as React from "react";
import axios from "axios";
import { withSnackbar } from "notistack";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { navigate } from "gatsby";
import Layout from "../Layout";
import TitleNav from "../TitleNav";
import AuthToken from "../../helpers/AuthToken";
import { staffRoles } from "../../helpers/Lists";
import { isBrowser, signOut } from "../../helpers/LocalStorage";

class InviteUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: ``,
      lastName: ``,
      email: ``,
      role: `User`,
    };
  }

  handleUpdate = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    const data = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      role: this.state.role,
    };

    event.preventDefault();
    AuthToken();
    axios
      .post(`${process.env.GATSBY_API_URL}/users`, data)
      .then(() => {
        if (isBrowser()) {
          navigate("/app/users");
        }
      })
      .catch((err) => {
        if (err.response.status === 401 || err.response.status === 403) {
          signOut();
        } else {
          this.props.enqueueSnackbar(err.response.data.reason, {
            variant: "error",
          });
          if (isBrowser()) {
            navigate("/app/users");
          }
        }
      });
  };

  render() {
    return (
      <Layout>
        <Paper elevation={1} sx={{ p: 2 }}>
          <TitleNav title="Invite User" buttonLink="/app/users" buttonTitle="Users" />
          <Box sx={{ m: 2 }} />
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  name="firstName"
                  value={this.state.firstName}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  value={this.state.lastName}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="role"
                  select
                  required
                  label="Role"
                  name="role"
                  value={this.state.role}
                  onChange={this.handleUpdate}
                  variant="outlined"
                  fullWidth
                >
                  {staffRoles().map((option) => (
                    <MenuItem value={option.value} key={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid container item xs={12} justifyContent="right">
                <Button type="submit" variant="contained" color="primary">
                  Send Invite
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Layout>
    );
  }
}

export default withSnackbar(InviteUser);
