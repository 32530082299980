import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import AuthToken from "../../helpers/AuthToken";
import { withSnackbar } from "notistack";
import { signOut } from "../../helpers/LocalStorage";
import axios from "axios";

class TechnologyTagsAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
    };
  }

  handleUpdate = (event, values) => {
    this.props.selectChanged(values);
  };

  componentDidMount() {
    AuthToken();
    axios
      .get(`${process.env.GATSBY_API_URL}/technology-tags?sparse=1`)
      .then((response) => {
        this.setState({
          options: response.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            this.props.enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          this.props.enqueueSnackbar("Cannot connect to server", {
            variant: "error",
          });
        }
      });
  }

  render() {
    return (
      <Autocomplete
        multiple
        id="verticalIds"
        options={this.state.options}
        getOptionLabel={(option) => option.label}
        filterSelectedOptions
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={this.handleUpdate}
        value={this.props.ids}
        renderInput={(params) => <TextField {...params} variant="outlined" label="Technology Tags" />}
      />
    );
  }
}

export default withSnackbar(TechnologyTagsAutocomplete);
